<template>
	<Application :loading="loading || productKindsLoading"
		:style="{ 'padding-left': isSideBarFixed ? '315px' : '15px' }"
	>
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color: transparent; min-width: 40px;" @click="goback()">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<span style="font-size: 17px; font-weight: 600; line-height: 22px;">{{ contractTemplateTitle }}</span>
		</template>
		<SideBar class="dcm-sidebar"
			:actions="sidebarActions"
			:navigation="sections"
			:preselectedActiveLink="'contracts'"
			@action="handleAction($event)"
		/>
		
		<!-- TODO: this should be a <Section> -->
		<div style="margin-top: 60px;" v-if="contractTemplate">
			<Disclosure :title="$t('text.contractInformation')" :error="!!childErrorMessage" expanded
				:message="childErrorMessage" ref="SECTION_contractInformation"
				:lock="hasContracts"
			>
				<FieldSet>
					<Field typeName="ContractTemplate" fieldName="name" v-model="contractTemplate.name" dataCy="name" />
					<Field typeName="ContractTemplate" fieldName="startDate" v-model="contractTemplate.startDate" dataCy="startDate" />
					<Field typeName="ContractTemplate" fieldName="dueDays" v-model="contractTemplate.dueDays" dataCy="dueDays" :infoText="$t('text.contractTemplateDueDaysInfoText')" />
					<Field typeName="ContractTemplate" fieldName="text" v-model="contractTemplate.text" dataCy="text" :infoText="$t('text.contractTemplateTextInfoText')" />
				</FieldSet>

				<FieldSet :label="$t('text.miscellaneous')">
					<Field
						typeName="ContractTemplate"
						fieldName="needsSignature"
						:model-value="doesNotNeedSignature"
						isOnTheRightSide
						@update:model-value="(n) => doesNotNeedSignature = n"
						:infoText="$t('text.contractTemplateNeedsSignatureInfoText')"
						dataCy="needsSignature"
						
					/>
				</FieldSet>
				<!-- <FieldSet :label="$t('text.eCommerceCategory')"
					:info-text="$t('text.contractTemplateProductKindsInfoText')" :required="true">
					<Field v-model="contractTemplate.productKinds" typeName=" ContractTemplate" fieldName="productKinds"
						dataCy="productKinds">
						<ContractKindSelector v-model="contractTemplate.productKinds.de" :productKinds="productKinds" />
					</Field>
				</FieldSet> -->
			</Disclosure>
		</div>
	</Application>
</template>

<script lang="ts">
import Common from '../../../mixins/Common.vue'
import SideBar from '../../../components/common/SideBar.vue'
import Application from '../Application.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import Disclosure from '../../../components/common/Disclosure.vue'
import Field from '../../../components/fields/Field.vue'
import ChildErrorReceiver from '../packageDesigner/ChildErrorReceiver.vue'
import FieldSet from '../packageDesigner/FieldSet.vue'
import ContractKindSelector from '../../../components/contract/ContractKindSelector.vue'
import type { ContractTemplate } from '../../../../../api2/src/modules/entities'
import Loading from '../../../mixins/Loading.vue'
import Toast from '../../../mixins/Toast.vue'

export default {
	name: 'ContractTemplateDesigner',
	components: { SideBar, Application, Disclosure, Field, FieldSet, ContractKindSelector },
	mixins: [ Common, ChildErrorReceiver, Loading, Toast ],
	props: {
		id: String
	},
	data: () => ({
		contractTemplate: null as ContractTemplate | null,
		sections: [],
		productKinds: [],
		loading: false,
		productKindsLoading: false,
		activeLink: '',
		hasContracts: false,
		doesNotNeedSignature: false
	}),
	computed: {
		contractTemplateTitle() {
			return this.contractTemplate?.name
		},
		sidebarActions() {
			const actions = []
			actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'saveContractTemplate', params: 'save', datacy: "save", disabled: this.childErrorMessage || this.hasContracts })
			return actions
		}
	},
	methods: {
		goback() {
			this.$store.state.contractsViewActiveLink = 'contracts'
			this.$router.push({
				path: '/contractManagement'
			})
		},
		async getContractTemplate() {
			try {
				this.loading = true
				let contractExecutive = new ContractExecutive(this)
				const result = await contractExecutive.getContractTemplateDetails(this.$store.state.selectedClient.sys.id, this.id)
				this.contractTemplate = result
				this.doesNotNeedSignature = !this.contractTemplate.needsSignature
			}
			catch (error) {
				this.showErrorToast()
			}
			finally {
				this.loading = false
			}
		},
		async checkContractTemplateHasContracts() {
			const contractExecutive = new ContractExecutive(this)
			this.hasContracts = await contractExecutive.contractTemplateHasContracts(this.id)
		},
		async saveContractTemplate() {
			await this.loadingOverlay(async () => {
				try {
					this.contractTemplate.needsSignature = !this.doesNotNeedSignature
					let contractExecutive = new ContractExecutive(this)
					await contractExecutive.upsertContractTemplate(this.$store.state.selectedClient.sys.id, this.contractTemplate)
					this.showSuccessToast()
					this.goback()
				}
				catch (error) {
					this.showErrorToast()
				}
			}, 'saveContractTemplate')
		},
		async getProductKinds() {
			this.productKindsLoading = true
			try {
				const response = await this.$httpGet(`/peak/attribute-sets/${this.$store.state.selectedClient.fields.installationId.de}`)
				this.productKinds = response?.attributeSets.map(x => x.attribute_set_name)
			}
			catch (error) {
				console.error('cannot load attribute sets', error)
			}
			this.productKindsLoading = false
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setActive(key) {
			this.activeLink = key
		},
	},
	async mounted() {
		this.sections.push({ id: 'contracts', state: 'valid' })
		//this.getProductKinds()
		if (this.id) {
			this.getContractTemplate()
			this.checkContractTemplateHasContracts()
		}
		else {
			let contractExecutive = new ContractExecutive(this)
			this.contractTemplate = await contractExecutive.contractTemplateFactory()
		}
	},
}
</script>