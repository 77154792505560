<template>
	<div v-if="dates.count > 0" ref="calendar"
		class="ColorCalendar"
		:class="{ remove: paintingProfile?.isRemove }"
		style="display: flex; gap: 10px;"
		:style="{ '--marquee': paintingProfile?.color }"
	>
		<div style="width: 280px; border: 1px solid #eee; border-radius: 5px; padding: 20px;">
		<div class="nav" style="white-space: nowrap;" v-if="!sub">
			<button class="jumpToday" @click="go('today')">
				<v-icon>mdi-calendar-today</v-icon>
			</button>
			<button class="prev" @click="prev" :disabled="focusStart < dates.fromMonday">
				<v-icon>mdi-chevron-left</v-icon>
			</button>
			<span v-if="calendar.length" class="header">
				<span class="monthHeader" @click="view = view == 'MONTHS' ? 'DAYS' : 'MONTHS'">{{ monthLabel(calendar[14]) }}</span>
				<span class="yearHeader" @click="view = view == 'YEARS' ? 'DAYS' : 'YEARS'">{{ calendar[14]?.year }}</span>
			</span>
			<button class="next" @click="next" :disabled="focusEnd > dates.toSunday">
				<v-icon>mdi-chevron-right</v-icon>
			</button>
			<slot name="navigation"></slot>
		</div>
		<!-- TODO: instead of a view show a dropdown? -->
		<div v-if="view == 'MONTHS'" class="months">
			<div v-for="month of months" :key="month"
				@click="go(month.date); view = 'DAYS'"
				:class="{
					selected: month.date.format('MM') == focusStart.format('MM'),
				}"
			>
				{{ month.name }}
			</div>
		</div>
		<div v-if="view == 'YEARS'" class="years">
			<div v-for="year of years" :key="year"
				@click="go(year.date); view = 'DAYS'"
				:class="{
					selected: year.date.format('YYYY') == focusStart.format('YYYY'),
				}"
			>
				{{ year.name }}
			</div>
		</div>
		<div v-if="view == 'DAYS'" style="display: flex; flex-wrap: wrap; font-size: smaller;">
			<div v-for="weekday of weekdays" :key="weekday"
				class="date weekdayHead"
			>
				{{ $t('text.' + weekday).substr(0, 1) }}
			</div>
			<div v-for="day of calendar" :key="'dt-' + day.date"
				class="date"
				:class="{
					open: day.open,
					notInRange: !day.inRange,
					monthBorder: day.date.substring(8, 10) < 8,
					today: dates.today == day.date,
					[ 'd' + day.date.substring(8, 10) ]: true,
					selected: day.selected,
					clicked: selectedDay?.date == day.date,
					fieldFocus: day.fieldFocus,
					error: !!day.error,
					notFocus: notFocus(day),
					marquee: marqueeDays?.[day.date],
					profiles: day.profiles !== undefined,
					[ 'profiles' + day.profiles?.length ]: day.profiles !== undefined,
					hasPaintingProfile: day.profiles?.find?.(p => p == paintingProfile),
					profilesMany: day.profiles?.length > 4,
				}"
				:style="{
					'--col': day.profile?.color,
					'--c1': day.profiles?.[0]?.color,
					'--c2': day.profiles?.[1]?.color,
					'--c3': day.profiles?.[2]?.color,
					'--c4': day.profiles?.[3]?.color,
					'--cPP': paintingProfile?.color,
				}"
				@click="clickDay(day)"
				@mousedown="mousedown($event, day)"
				@mousemove="mousemove($event, day)"
				@mouseup="mouseup($event)"
				@mouseover="mouseover($event, day)"
				@mouseout="mouseout($event, day)"
			>
				<span class="day">{{ Number(day.date.substring(8,10)) }}</span>
<!--
				<span v-if="day.date.substring(8,10) == '01' || day.weekday == 'monday'" class="monthLabel">
					{{ monthLabel(day) }}
					<span v-if="day.date.substring(8,10) == '01'">{{ day.year }}</span>
				</span>
-->
<!--
				<v-icon class="dots hover" v-if="selectedDay?.date != day.date">mdi-dots-horizontal</v-icon>
				<v-menu v-if="selectedDay?.date == day.date"
					v-model="dayMenu"
					location="top center"
					:close-on-content-click="false"
				>
					<template #activator="{ props }">
						<v-icon v-bind="props" class="dots" v-if="!dayMenu">mdi-dots-horizontal</v-icon>
						<v-icon v-bind="props" class="dots" v-if="dayMenu">mdi-dots-horizontal-circle</v-icon>
					</template>
					<div class="DataCalendarMenu">
						<div style="font-weight: bold; font-size: smaller;">{{ day.date.substring(8,10) }} {{ monthLabel(day) }} {{ day.year }}</div>
						<slot name="dayMenu" :day="day"></slot>
					</div>
				</v-menu>
				<slot name="day" :day="day" :selectedDay="selectedDay"></slot>
-->
			</div>
		</div>
		</div>
		<!-- year-overview experiment
		TODO: is this even possible with the current architecture?
			we require "calendar" to be set by the parent, but these sub cals actually need other ranges.
			possibly we need to feed an additional "overviewCalendar" prop to the sub cals?
		TODO: dont show labels on sub cals, reduce spacing, reduce border radius?
		TODO: show below the main cal
		<div style="display: flex;">
			<ColorCalendar v-if="!sub" :dates="dates" :calendar="calendar" :profiles="profiles" :sub="true"
				style="zoom: 0.25;"
			/>
			<ColorCalendar v-if="!sub" :dates="dates" :calendar="calendar" :profiles="profiles" :sub="true"
				style="zoom: 0.25;"
			/>
			<ColorCalendar v-if="!sub" :dates="dates" :calendar="calendar" :profiles="profiles" :sub="true"
				style="zoom: 0.25;"
			/>
			<ColorCalendar v-if="!sub" :dates="dates" :calendar="calendar" :profiles="profiles" :sub="true"
				style="zoom: 0.25;"
			/>
		</div>
		-->
		<HoverOverlay v-if="mouseoverDayEvent && $slots[ 'day-hover' ]"
			:target="mouseoverDayEvent.target"
		>
			<slot name="day-hover" :day="mouseoverDay"></slot>
		</HoverOverlay>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import HoverOverlay from '../common/HoverOverlay.vue'

// TODO: BUG: when clicking 2nd view tab and back, this messes up the calendar (cannot navigate, ..)
// TODO: do we really need the weekdays?
//       we can live without them on the DataCalendars also..

// fieldFocus: the integrator may set day.fieldFocus = true to highlight a day with a dark blur circle
// focus: true between focusStart and focusEnd. however as this is a month calendar, we may show more days of the start and end week, on those days focus is false
//        a day may also have an explicit .focus property for this for advanced cases
// inRange: true if the day is between dates.min and dates.max (controlled by the integrator)

export default {
	components: { HoverOverlay },
	props: {
		// {
		//    "count": 2562,
		//    "fromDate": { MOMENT },
		//    "fromMonday": { MOMENT },
		//    "minDate": "2024-01-01",
		//    "maxDate":"2030-12-31",
		//    "offsetToday": 210,
		//    "toDate": { MOMENT },
		//    "toMonday": { MOMENT },
		//    "today": "2024-08-08",
		// }
		dates: Object,
		calendar: Array,
		profiles: Array,
		paintingProfile: Object,
		modelValue: Object, // Day
		sub: Boolean,
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		editingDay: null,
		selectedDay: null,
		dayMenu: false,
		jumpMenu: false,
		jumpSearch: '',
		focusStart: moment().startOf('month'),
		focusEnd: moment().endOf('month'),
		mousedownDay: null,
		mouseoverDay: null,
		mouseoverDayEvent: null,
		view: 'DAYS', // DAYS | MONTHS | YEARS
	}),
	computed: {
		innerWidth() {
			return window.innerWidth
		},
		marqueeDays() {
			if (!this.mousedownDay) return {}
			if (!this.mouseoverDay) return {}
			const x1 = Math.min(this.mousedownDay.x, this.mouseoverDay?.x)
			const x2 = Math.max(this.mousedownDay.x, this.mouseoverDay?.x)
			const y1 = Math.min(this.mousedownDay.y, this.mouseoverDay?.y)
			const y2 = Math.max(this.mousedownDay.y, this.mouseoverDay?.y)
			const r = {}
			for (const day of this.calendar) {
				if (day.x < x1 || day.x > x2 || day.y < y1 || day.y > y2) continue
				r[day.date] = true
			}
			return r
		},
		months() {
			const r = []
			for (let i = 0; i < 12; i++) {
				const d = this.focusStart.clone().month(i)
				r.push({ value: i, date: d.startOf('month'), name: this.monthLabel({ dateObject: d }) })
			}
			return r
		},
		years() {
			const r = []
			for (let i = 2020; i < 2032; i++) {
				const d = this.focusStart.clone().year(i)
				r.push({ value: i, name: i, date: d.year(i) })
			}
			return r
		},
	},
	watch: {
		modelValue(n) {
			this.selectedDay = this.modelValue
		},
	},
	methods: {
		monthLabel(day) {
			if (!day) return ''
			const locale = this.$vuetify.locale.current
			return Intl.DateTimeFormat(locale, { month: 'short' }).format(day.dateObject)
		},
		go(day) {
			if (!day) return
			if (day == 'today') day = this.dates.today
			if (day instanceof Date) day = moment(day).startOf('day')
			if (typeof day == 'string') day = moment(day).startOf('day')
			this.focusStart = day.clone().startOf('month')
			this.focusEnd = this.focusStart.clone().endOf('month')
			this.$emit('focusRangeChanged', { focusStart: this.focusStart, focusEnd: this.focusEnd })
			const target = this.focusStart.clone().startOf('isoWeek')
		},
		prev() {
			if (this.focusStart) return this.go(this.focusStart.clone().add(-1, 'month'))
		},
		next() {
			if (this.focusStart) return this.go(this.focusStart.clone().add(+1, 'month'))
		},
		clickDay(day) {
			this.selectedDay = day
			this.$emit('update:modelValue', day)
			this.selectedTime = null
			this.dayMenu = true
			this.addTime = null
			// TODO: event?
//			day.profile = this.paintingProfile
		},
		notFocus(day) {
			if (day.focus === false) return true
			if (day.focus === true) return false
			if (!this.focusStart) return true
			if (day.date < this.focusStart.format('YYYY-MM-DD')) return true
			if (day.date > this.focusEnd.format('YYYY-MM-DD')) return true
			return false
		},
		// marquee painting
		mousedown(e, day) {
			if (!this.paintingProfile) return
			e.preventDefault()
			this.$forceUpdate()
			this.mousedownDay = day
			window.addEventListener('mouseup', this.mouseup)
		},
		mousemove(e, day) {
			this.mouseoverDay = day
			if (!this.mousedownDay) return
			this.$forceUpdate()
		},
		mouseup(e) {
			const days = []
			for (const day of this.calendar) {
				if (!this.marqueeDays[day.date]) continue
				if (this.notFocus(day)) continue
				days.push(day)
			}
			this.$emit('painted', { days, profile: this.paintingProfile })
			window.removeEventListener('mouseup', this.mouseup)
			this.mousedownDay = null
		},
		mouseover(e, day) {
			if (this.mouseoverDay == day) return
			this.mouseoverDayEvent = e
			this.$refs.calendar.getBoundingClientRect()
			this.$emit('mouseoverDay', e, day)
		},
		mouseout(e, day) {
			this.$emit('mouseoutDay', e, day)
			this.mouseoverDayEvent = null
			this.mouseoverDay = null
		},
		snap(v, snap) {
			return Math.round(v / snap) * snap
		},
	},
	beforeMount() {
		if (this.modelValue) {
			this.selectedDay = this.modelValue
			this.go(this.modelValue.date)
		}
		else {
			this.go('today')
		}
	},
}
</script>

<style scoped>
.ColorCalendar { --sel: rgb(0, 174, 239); --field-focus: #295dc7; }

/* TODO: sticky distance.. */
.nav { display: flex; gap: 10px; align-items: center; position: sticky; top: /*64*/0px; background: white; z-index: 100; padding-top: 15px; padding-bottom: 10px; margin-top: -15px; }
.nav button { padding: 5px; border: 1px solid #999; border-radius: 5px; background-color: #fff; }
.nav button[disabled] { opacity: 0.5; cursor: default; }
.nav button.jumpToday,
.nav button.prev,
.nav button.next { padding: 0; border: none; }

.weekdayHead { width: 100%; height: 25px !important; text-align: center; font-weight: 600; margin-bottom: 5px; min-height: initial !important; }
.monthLabel { font-weight: bold; color: #98926e; }

.date { width: calc(100% / 7); box-sizing: border-box; padding: 3px 2px; cursor: pointer; position: relative; }
.date.open { background-color: #fff6c6; }
.date.error { background-color: #fbd5d5; }
.date.notInRange { opacity: 0.5; }
.date.notFocus { filter: saturate(0); opacity: 0.3; }
.date.selected { outline: 2px solid var(--sel) !important; outline-offset: -2px; z-index: 10; border-radius: 20px; }
.date.marquee { outline: 4px solid var(--marquee); outline-offset: -3px; z-index: 10; border-radius: 20px; }
.remove .date.marquee { outline: 2.5px dotted red; }

.date .day { display: inline-block; width: 100%; text-align: center; background: #e7e1e1; /*e8f5dc;*/ border-radius: 20px; padding: 5px; pointer-events: none; }
.date.today { z-index: 10 !important; }
.date.today .day { outline: 2px solid rgb(255, 0, 0, 0.6); outline-offset: 4px; }
.date .day { background: var(--col); }
.date.fieldFocus .day { outline: 2px solid var(--field-focus); outline-offset: -2px; }
.date.clicked .day { outline: /*3px dotted #333*/ 2px solid var(--field-focus); outline-offset: -2px; }

.dots { float: right; }
.date > .dots.hover { display: none; }
.date:hover > .dots.hover { display: block; }

.header { display: flex; gap: 5px; font-family: monospace; }
.monthHeader,
.yearHeader { cursor: pointer; }
.monthHeader:hover,
.yearHeader:hover { text-decoration: underline; }
.months,
.years { display: flex; flex-wrap: wrap; flex-wrap: wrap; gap: 5px; font-family: monospace; }
.months > div,
.years > div { cursor: pointer; padding: 10px; text-align: center; width: 55px; }
.months > div:hover,
.years > div:hover { text-decoration: underline; }
.months .selected,
.years .selected { outline: 2px solid var(--field-focus); outline-offset: -2px; border-radius: 10px; }

.week { display: flex; gap: 15px; font-family: sans-serif; zoom: 4; flex-wrap: wrap; margin: 15px; }
.week div { text-align: center; }

/* multiple profiles */
/* TODO: this z-index is probably not ok */
.date { z-index: 9; height: 34px; position: relative; background: white; }
.profiles::before { z-index: -1; content: ''; position: absolute; inset: 2px; border-radius: 100%; background: #eee; }
.profiles .day { position: absolute; background: white; inset: 6px; padding: 2px; display: block; text-align: center; width: auto; }

.hasPaintingProfile .day { background: var(--cPP); filter: brightness(1.01) contrast(1.1); }

.profiles1::before { background: var(--c1); }
.profiles2::before { background: conic-gradient(var(--c1) 0%, var(--c1) 40%, var(--c2) 50%, var(--c2) 90%, var(--c1) 100%); }
.profiles3::before { background: conic-gradient(var(--c1) 0%, var(--c1) 25%, var(--c2) 35%, var(--c2) 55%, var(--c3) 65%, var(--c3) 90%, var(--c1) 100%); }
.profiles4::before { background: conic-gradient(var(--c1) 0%, var(--c1) 15%, var(--c2) 25%, var(--c2) 40%, var(--c3) 50%, var(--c3) 65%, var(--c4) 75%, var(--c4) 90%, var(--c1) 100%); }
.profilesMany::before { background: conic-gradient(rgba(255, 0, 0, 0.4) 0%, rgba(255, 154, 0, 0.4) 10%, rgba(255, 242, 0, 0.4) 20%, rgba(79, 220, 74, 0.4) 30%, rgba(63, 218, 216, 0.4) 40%, rgba(47, 201, 226, 0.4) 50%, rgba(28, 127, 238, 0.4) 60%, rgba(95, 21, 242, 0.4) 70%, rgba(186, 12, 248, 0.4) 80%, rgba(251, 7, 217, 0.4) 90%, rgba(255, 0, 0, 0.4) 100% ); }

.alt.profiles1::before { background: var(--c1); }
.alt.profiles2::before { background: conic-gradient(var(--c1) 0%, var(--c1) 45%, transparent 46%, transparent 49%, var(--c2) 50%, var(--c2) 95%, transparent 96%, transparent 99%, var(--c1) 100% ); }
.alt.profiles3::before { background: conic-gradient(var(--c1) 0%, var(--c1) 30%, transparent 31%, transparent 34%, var(--c2) 35%, var(--c2) 60%, transparent 61%, transparent 64%, var(--c3) 65%, var(--c3) 95%, transparent 96%, transparent 99%, var(--c1) 100%); }
.alt.profiles4::before { background: conic-gradient(var(--c1) 0%, var(--c1) 20%, transparent 21%, transparent 24%, var(--c2) 25%, var(--c2) 45%, transparent 46%, transparent 49%, var(--c3) 50%, var(--c3) 70%, transparent 71%, transparent 74%, var(--c4) 75%, var(--c4) 95%, transparent 96%, transparent 99%, var(--c1) 100%); }
.alt.profilesMany::before { background: conic-gradient(rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100% ); }
</style>

<style>
.DataCalendarMenu { padding: 5px 0; background: white; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); }
.DataCalendarMenu > div { white-space: nowrap; cursor: pointer; padding: 5px 10px; }
.DataCalendarMenu > .item:hover { background: #eee; }
</style>