import { Entity, EntityRepositoryType, PrimaryKey, Property } from '@mikro-orm/postgresql'
import { EntityRepository } from '@mikro-orm/postgresql'
import { Field } from '../../framework/model-util'
import { BaseEntity } from '../core/core.entities'

class InfoTypeRepository extends EntityRepository<InfoType> {}

@Entity({ repository: () => InfoTypeRepository })
export class InfoType extends BaseEntity {
	[EntityRepositoryType]?: InfoTypeRepository

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	key?: string

	@Property()
	@Field({ type: 'Symbol', localized: true, required: true })
	name: Record<string, string>

	@Property()
	@Field({ type: 'Symbol', localized: false, required: false })
	icon?: string

	constructor(key: string) {
		super()
		this.key = key
	}
}
