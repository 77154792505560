import { CheckPolicy, Resource } from '../../framework/permission-util'
import { RT } from '../permissions'
import Executive from '../../framework/rmi-util'
import { register } from '../../framework/rmi-server-util'
import { buildGuessCategoriesPrePrompt, buildGuessCategoriesPrompt, getOrCreateAssistant, getOrCreateThread, ask, getCategories, buildGuessCategoriesResponse, getCustomerSegmentTags, buildGuessCustomerSegmentTagsResponse, buildGuessCustomerSegmentTagsPrompt, buildGuessCustomerSegmentTagsPrePrompt, cleanupAiThreads } from './AiTechnical'
import { CleanupResult } from '../contract/util/types'

class I18nString {
	de: string
	[ locale: string ]: string
}

export class GuessCategoryRequest {
	title: I18nString
	shortDescription: I18nString
	longDescription: I18nString
	address: string
}

export class GuessCustomerSegmentTagsRequest {
	title: I18nString
	shortDescription: I18nString
	longDescription: I18nString
	address: string
}

export default class AiExecutive extends Executive {

	initServer() {
	}

	// @CheckPolicy()
	async guessCategory(@Resource(RT.CLIENT) clientId: string, request: GuessCategoryRequest): Promise<string[]> {
		// currently we are only using one assistant
		const assistant = await getOrCreateAssistant(clientId, 'STANDARD', 'Standard Assistant')

		// we find or create a thread that fits the current request
		const contextKey = 'product-guessCategory'
		const categories = await getCategories(clientId)
		const prePrompt = await buildGuessCategoriesPrePrompt(clientId, categories)
		const thread = await getOrCreateThread(clientId, contextKey, prePrompt)

		// create message on the thread
		const prompt = await buildGuessCategoriesPrompt(request)
		const response = await ask(assistant, thread, prompt, clientId)
		return await buildGuessCategoriesResponse(response, categories)
	}

	// @CheckPolicy()
	async guessCustomerSegmentTags(@Resource(RT.CLIENT) clientId: string, request: GuessCustomerSegmentTagsRequest): Promise<Object> {
		const assistant = await getOrCreateAssistant(clientId, 'STANDARD', 'Standard Assistant')
		const contextKey = 'guessCustomerSegmentTags'
		const customerSegmentTags = await getCustomerSegmentTags(clientId)

		const prePrompt = buildGuessCustomerSegmentTagsPrePrompt(customerSegmentTags)
		const thread = await getOrCreateThread(clientId, contextKey, prePrompt)

		const prompt = buildGuessCustomerSegmentTagsPrompt(request)
		const response = await ask(assistant, thread, prompt, clientId)

		return buildGuessCustomerSegmentTagsResponse(response, customerSegmentTags)
	}

	// @CheckPolicy()
	async cleanupAiThreads(@Resource(RT.CLIENT) clientId: string): Promise<CleanupResult> {
		return await cleanupAiThreads()
	}
}

register(AiExecutive)