<template>
	<Application class="ContractSigningView" :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail"
		v-model:successTitle="successTitle" :loading="loading"
		:style="{ 'padding-left': isSideBarFixed && !isMobile ? '315px' : '15px' }"
	>
		<!-- TODO: we should not use bootstrap classes -->
		<template #navbar>
			<v-spacer></v-spacer>
			<ButtonList
				v-if="signedContracts?.length && selectedContract"
				:buttonText="$t('text.download')"
				:options="signedContracts.map(contract => ({ key: contract.contractTemplate?.name }))"
				prependIcon="mdi-download"
				@option-click="handleDownload"
			/>
		</template>

		<template v-if="tokenIsValid">
			<SideBar class="signing-sidebar"
				:preselectedActiveLink="'contracts'" @action="handleAction($event)"
				:class="{ mobileSidebar: isMobile }"
			>
				<template #customActions>
					<div class="pa-4 flex-column d-flex">
						<span class="pt-1 mb-4 sidebar-title">{{ $t('text.actions') }}</span>
						<ContractCard v-for="contract in contracts" :key="contract.id" :contract="contract"
							:client-name="clientNames[contract.contractTemplate?.contractSequence?.clientId]"
							class="mb-4" @click="setActiveLink(contract.id)" style="cursor: pointer;" />
					</div>
				</template>

				<template #customNavigation>
					<div class="pb-4 flex-column d-flex signing-sidebar-navigation">
						<span class="sidebar-title pa-4">{{ $t('text.sidebar') }}</span>
						<SideBarNavigation v-for="navigation in navigations" :key="navigation.clientId"
							:model-value="selectedContract?.id" :title="navigation.title"
							:sections="navigation.contracts" :expanded="true"
							:title-error-icon="hasUnsignedContracts(navigation.clientId) ? 'mdi-alert' : null"
							:preselected-active-link="selectedContract?.id" @setActive="setActiveLink($event)" />
						<div class="bottom-navigation">
							<div :class="{ active: viewTerminatedContracts }" @click="setViewTerminatedContracts">
								<span>{{ $t('text.terminatedContracts') }}</span>
							</div>
						</div>
					</div>
				</template>
			</SideBar>

			<div style="margin-top: 60px;" v-if="viewTerminatedContracts">
				<Disclosure :title="$t('text.terminatedContracts')" expanded class="terminated-disclosure">
					<FieldSet :label="$t('text.terminatedContracts')" :info-text="$t('text.terminatedContractsInfoText')">
						<ContractsList :contracts="terminatedContracts" :contractors="contractors" :terminated="true" :client-names="clientNames" />
					</FieldSet>
				</Disclosure>
			</div>
			<div style="margin-top: 60px;" v-else-if="selectedContract">
				<Disclosure expanded class="signing-disclosure">
					<template #header-accessory>
						<div class="header-accessory">
							<v-icon size="24px">mdi-gavel</v-icon>
							<div>
								<span>
									{{ selectedContract.contractTemplate.name }}
								</span>
								<span>
									{{ $t('text.issuedOn') }} {{ issuedOnText }}
								</span>
							</div>
							<div>
								<div class="d-flex flex-column">
									<div class="d-flex">
										<v-spacer></v-spacer>
										<div :style="{ 'background-color': backgroundColorChip }" class="status-chip">
											<v-icon size="18px">mdi-draw</v-icon>
											<span>{{ statusText }}</span>
										</div>
									</div>
									<div class="d-flex">
										<v-spacer></v-spacer>
										<span class="info-text" :style="{ 'color': textColor }" v-html="infoText" />
									</div>
								</div>
							</div>
						</div>
					</template>
					<div ref="contractInformation" class="contract-information">
						<template v-if="isPhysical">
							<FieldSet class="physical-title" :label="$t('text.physicalContractInfo')">
								<span class="physical-text">{{ $t('text.physicalContractInfoText') }}</span>
							</FieldSet>
							<FieldSet class="mt-8" :label="$t('text.yourData')"
								:info-text="$t('text.yourDataInfoText')" />
							<FieldSet class="mt-8">
								<div class="d-flex">
									<v-icon class="mr-4" size="24px">mdi-information</v-icon>
									<span class="physical-text">{{ $t('text.noOnlineDataAvailable') }}</span>
								</div>
							</FieldSet>
						</template>
						<template v-else>
							<ContractDetail :show-contractor="true" :contract="selectedContract" :contractor="contractor"
								:pdf-export-loading="pdfExportLoading" @openDialog="openDialog()" :readOnly="isReadonly"
							/>
							<template v-if="toBeSigned && !isReadonly">
								<FieldSet class="mt-8">
									<div class="d-flex flex-wrap" style="gap: 24px;">
										<!-- TODO: we should not override title -->
										<!-- TODO: why is width: 100% necessary here? -->
										<Field :title="`${$t('text.signatureName')} (${$t('text.required')})`"
											class="flex-grow-1" typeName="Contract" fieldName="signatureName"
											v-model="signatureName" dataCy="signatureName"
										/>
										<!-- TODO: we should not override title -->
										<!-- TODO: why is width: 100% necessary here? -->
										<Field :title="`${$t('text.signaturePosition')} (${$t('text.required')})`"
											class="flex-grow-1" typeName="Contract" fieldName="signaturePosition"
											v-model="signaturePosition" dataCy="signaturePosition"
										/>
									</div>
									<v-checkbox id="confirmCheck" hide-details :label="$t('text.contractConfirmToS')" v-model="confirmCheck" color="blue" />
									<v-checkbox id="downloadCheck" hide-details :label="$t('text.downloadContract')" v-model="downloadCheck" color="blue" />
								</FieldSet>
								<div v-if="!pdfExportLoading" class="d-flex mt-8">
									<v-spacer></v-spacer>
									<v-btn class="greenButton" theme="dark" elevation="0" data-cy="confirm"
										@click="signContract" :disabled="disableConfirmButton"
									>
										{{ $t('text.signContract') }}
									</v-btn>
								</div>
							</template>
						</template>
						<div v-if="isPhysical || !toBeSigned" class="d-flex mt-8">
							<ContractCard :contract="selectedContract"
								:style="{
									flex: !pdfExportLoading ? 0.4 : 1,
									minHeight: '130px',
									width: isMobile ? '100%' : 'auto',
								}"
							/>
						</div>
					</div>
				</Disclosure>
			</div>

			<DialogV2 ref="contractorDialog"
				:title="$t('text.editContractorInformation')"
				:onConfirm="updateContractor"
				:disableConfirmButton="hasFormError"
				:height="isMobile ? 'calc(100vh - 140px)' : 'calc(100vh - 5px)'"
			>
				<template #content>
					<ContractorForm v-model="contractorClone"
						@update:modelValue="contractorClone = $event"
						@update:hasFormError="hasFormError = $event"
					/>
				</template>
			</DialogV2>
		</template>
	</Application>
</template>

<script>
import Common from '../../../mixins/Common.vue'
import Application from '../Application.vue'
import ContractExecutive from '../../../../../api2/src/modules/contract/ContractExecutive'
import DialogV2 from '../../../components/common/DialogV2.vue'
import ContractorForm from '../../../components/contract/ContractorForm.vue'
import ContractCard from '../../../components/contract/ContractCard.vue'
import SideBar from '../../../components/common/SideBar.vue'
import SideBarNavigation from '../../../components/common/SideBarNavigation.vue'
import Disclosure from '../../../components/common/Disclosure.vue'
import FieldSet from '../packageDesigner/FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import Loading from '../../../mixins/Loading.vue'
import ButtonList from '../../../components/common/ButtonList.vue'
import ContractDetail from '../../../components/contract/ContractDetail.vue'
import ContractsList from '../../../components/contract/ContractsList.vue'

export default {
	components: { Application, DialogV2, ContractorForm, SideBar, ContractCard, SideBarNavigation, Disclosure, FieldSet, Field, ButtonList, ContractDetail, ContractsList },
	mixins: [ Common, Loading ],
	props: {
		token: { type: String, required: true },
	},
	data: () => ({
		tokenIsValid: false,
		contractors: [],
		contractsByContractor: {},
		contracts: [],
		loading: false,
		clientNames: {},
		hasFormError: false,
		selectedContract: null,
		contractor: null,
		contractorClone: null,
		confirmCheck: false,
		downloadCheck: false,
		pdfExportLoading: false,
		signatureName: '',
		signaturePosition: '',
		windowWidth: window.innerWidth,
		viewTerminatedContracts: false,
		terminatedContracts: [],
		isReadonly: false
	}),
	computed: {
		isMobile() {
			return this.windowWidth < 768
		},
		navigations() {
			const grouped = []

			this.contracts?.forEach(item => {
				const clientId = item.contractTemplate.contractSequence.clientId
				const id = item.id
				const title = item.contractTemplate.name

				let group = grouped.find(g => g.clientId === clientId)

				if (!group) {
					group = { clientId, title: this.clientNames[clientId], contracts: [] }
					grouped.push(group)
				}

				group.contracts.push({
					id, title,
					state: item.signedDate ? 'valid' : 'error',
					errorIconColor: 'black',
					validIcon: true,
					validIconColor: 'black',
				})
			})
			return grouped
		},
		signedContracts() {
			return this.contracts?.filter(x => !!x.signedDate)
		},
		isPhysical() {
			return !this.selectedContract?.contractTemplate?.needsSignature
		},
		isSigned() {
			return !!this.selectedContract?.signedDate
		},
		toBeSigned() {
			// TODO: in Phase 1 & 2 we allow overdue contracts to be signed. In a future phase we might have to check if the due date is set and if it is, check if it is in the past to prevent signing overdue contracts.
			return !this.isSigned
		},
		overdue() {
			return !this.isSigned && this.selectedContract?.dueDate && moment().isAfter(moment(this.selectedContract?.dueDate))
		},
		backgroundColorChip() {
			if (this.isSigned || this.isPhysical) return '#64C823'
			if (this.overdue) return '#F34545'
			if (this.toBeSigned) return '#FFB400'
			return '#8F8F8F'
		},
		statusText() {
			if (this.isSigned || this.isPhysical) return this.$t('text.signed')
			return this.$t('text.unsigned')
		},
		infoText() {
			if (this.isPhysical) return ''
			if (this.isSigned) {
				const signedDate = moment(this.selectedContract?.signedDate).format('DD.MM.YYYY')
				const signedTime = moment(this.selectedContract?.signedDate).format('HH:mm:ss')
				const signedBy = this.selectedContract?.signatureName
				const signedByPosition = this.selectedContract?.signaturePosition
				return `${ this.$t('text.signedOn') } <b>${ signedDate } ${ this.$t('text.at') } ${ signedTime }</b> by <b>${ signedBy }, ${ signedByPosition }</b>`
			}
			if (this.toBeSigned) {
				const dueDate = moment(this.selectedContract?.dueDate).format('DD.MM.YYYY')
				return `<b>${ this.$t('text.signUntil') } ${ dueDate }</b>`
			}
			if (this.overdue) {
				return `<b>${ this.$t('text.notSignedInTime') }</b>`
			}
			return ''
		},
		issuedOnText() {
			const createdDate = moment(this.selectedContract?.createDate).format('DD.MM.YYYY')
			const createdTime = moment(this.selectedContract?.createDate).format('HH:mm:ss')
			return `${ this.$t('text.issuedOn') } ${ createdDate } ${this.$t('text.at')} ${ createdTime }`
		},
		textColor() {
			if (this.isSigned) return '#28500E'
			if (this.toBeSigned) return '#664800'
			if (this.overdue) return '#611C1C'
			return '#DBDBDB'
		},
		disableConfirmButton() {
			return this.hasFormError || !this.signatureName || !this.signaturePosition || !this.confirmCheck
		},
		loggedInEmail () {
			if (!this.userIsServiceProvider) {
				return null
			}
			const serviceProvider = this.$store.state.isImpersonation ? this.$store.state.selectedServiceProvider : this.$store.state.loggedInUser?.fields?.serviceProvider?.de
			return serviceProvider?.fields?.userAccount?.de?.fields?.email?.de
		},
	},
	watch: {
		'selectedContract.id'() {
			this.downloadCheck = false
			this.confirmCheck = false
		}
	},
	async mounted() {
		window.addEventListener('resize', this.onResize)
		await this.getContractorAndContracts()
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.onResize)
	},
	methods: {
		downloadContract(contract) {
			const tempContract = { ...this.selectedContract }
			this.selectedContract = contract

			this.contractor = this.findContractOwner(contract.id)

			this.pdfExportLoading = true
			const clientName = this.clientNames[contract.contractTemplate?.contractSequence?.clientId]
			const contractName = contract.contractTemplate.name
			const contractSignDate = contract.signedDate
			const sanitizedClientName = clientName.replace(/[^\w\s]/gi, '')
			const sanitizedContractName = contractName.replace(/[^\w\s]/gi, '')
			const dateString = moment(contractSignDate).format('DD.MM.YYYY HH-mm')
			const pdfName = `${sanitizedClientName} ${sanitizedContractName} SIGNED ${dateString}.pdf`
			const options = {
				margin: 0.5,
				filename: pdfName,
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
				pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
			}
			this.$nextTick(() => {
				try {
					html2pdf().set(options).from(this.$refs.contractInformation).save();
				}
				catch (error) {
					this.showError(error.message)
				}
				finally {
					this.contractor = this.findContractOwner(tempContract.id)
					this.selectedContract = tempContract
					this.pdfExportLoading = false
				}
			})
		},
		hasUnsignedContracts(clientId) {
			return this.navigations.find(x => x.clientId === clientId)?.contracts?.some(x => x.state === 'error')
		},
		onResize() {
			this.windowWidth = window.innerWidth
		},
		openDialog() {
			if (this.isReadonly) return

			// Use first contractor's data for initial form
			if (!this.contractorClone) {
				this.contractorClone = JSON.parse(JSON.stringify(this.contractors[0]))
			}
			else {
				this.contractorClone = JSON.parse(JSON.stringify(this.contractor))
			}
			if (this.$refs.contractorDialog) {
				this.$refs.contractorDialog.open()
			}
		},
		async getContractsAndContractors() {
			let contractExecutive = new ContractExecutive(this)
			let result
			if (this.token) {
				result = await contractExecutive.getContractsAndContractorsByToken(null, this.token)
			}
			else {
				if (!this.loggedInEmail) {
					this.showError('Not Authorized!')
					setTimeout(() => {
						this.$emit('show-login')
					}, 1000)
					return
				}
				result =  await contractExecutive.getContractsAndContractorsByEmail(null, this.loggedInEmail)
			}
			this.contractors = result.contractors
			this.contractsByContractor = result.contractsByContractor
			this.isReadonly = result.isReadonly
			// filter non terminated
			const allContracts = Object.values(result.contractsByContractor).flat()
			this.terminatedContracts = allContracts.filter(contract => contract.status === 'TERMINATED')
			this.contracts = allContracts.filter(contract => !contract.endDate || moment(contract.endDate).isSameOrAfter(moment()))
			this.clientNames = result.clientNames
		},
		async getContractorAndContracts() {
			this.loading = true
			try {
				await this.getContractsAndContractors()
				this.tokenIsValid = true

				if (this.contracts.length) {
					this.setActiveLink(this.contracts[0].id)
				}
				
				this.$nextTick(() => {
					if (!this.isReadonly) {
						this.openDialog()
					}
				})
			}
			catch (error) {
				this.showError(error.message)
				setTimeout(() => {
					this.$emit('show-login')
				}, 5000)
			}
			this.loading = false
		},
		async updateContractor() {
			if (this.isReadonly) return

			this.loading = true
			try {
				let contractExecutive = new ContractExecutive(this)
				
				// Update all contractors with same data
				if (this.token) {
					await contractExecutive.bulkUpdateContractorsWithToken(null, this.contractors.map(c => ({
						...c,
						email: this.contractorClone.email,
						businessProfileData: this.contractorClone.businessProfileData
					})), this.token)
				}
				else {
					await contractExecutive.bulkUpdateContractors(null, this.contractors.map(c => ({
						...c,
						email: this.contractorClone.email,
						businessProfileData: this.contractorClone.businessProfileData
					})))
				}
				
				// Refresh data after update
				await this.getContractsAndContractors()
				
				// Find the contractor that owns the selected contract
				const contractOwner = this.findContractOwner(this.selectedContract.id)
				this.contractor = contractOwner
				this.contractorClone = JSON.parse(JSON.stringify(contractOwner))
				
				this.setActiveLink(this.selectedContract.id)
			}
			catch (error) {
				this.showError(error.message)
			}
			this.loading = false
		},
		findContractOwner(contractId) {
			for (const [contractorId, contracts] of Object.entries(this.contractsByContractor)) {
				if (contracts.some(c => c.id === contractId)) {
					return this.contractors.find(c => c.id === contractorId)
				}
			}
			return null
		},
		async signContract() {
			if (this.isReadonly) return

			await this.loadingOverlay(async () => {
				try {
					let contractExecutive = new ContractExecutive(this)
					const contractorId = this.findContractOwner(this.selectedContract.id)?.id
					const clientId = this.$store.state?.selectedClient?.sys?.id ?? this.selectedContract.contractTemplate.contractSequence.clientId
					
					if (this.token) {
						await contractExecutive.signContractsWithToken(clientId, contractorId, [this.selectedContract.id], this.signatureName, this.signaturePosition, this.token)
					}
					else {
						await contractExecutive.signContracts(clientId, contractorId, [this.selectedContract.id], this.signatureName, this.signaturePosition)
					}
					await this.getContractsAndContractors()
	
					// Find the contractor that owns the selected contract
					const contractOwner = this.findContractOwner(this.selectedContract.id)
					this.contractor = contractOwner
					this.contractorClone = JSON.parse(JSON.stringify(contractOwner))
	
					this.setActiveLink(this.selectedContract.id)
	
					if (this.downloadCheck) {
						this.downloadContract(this.selectedContract)
					}
				}
				catch (error) {
					console.error(error)
					this.showError(error.message)
				}
			}, 'signContract')
		},
		setViewTerminatedContracts() {
			this.viewTerminatedContracts = true
			this.selectedContract = null
			this.contractor = null
		},
		setActiveLink(id) {
			this.viewTerminatedContracts = false
			const foundContract = this.contracts.find(x => x.id === id)
			if (foundContract) {
				this.selectedContract = JSON.parse(JSON.stringify(foundContract))
				// Set the contractor based on the selected contract
				this.contractor = this.findContractOwner(id)
			}
		},
		handleDownload(optionKey) {
			const contract = this.signedContracts.find(
				contract => contract.contractTemplate?.name === optionKey
			)
			if (contract) {
				this.downloadContract(contract)
			}
		}
	},
}
</script>

<style lang="scss">
.ContractSigningView {
	.terminated-disclosure,
	.signing-disclosure {
		 > .v-expansion-panel {
			padding: 0;
			> .v-expansion-panel-text > .v-expansion-panel-text__wrapper {
				padding: 16px;

				@media (min-width: 768px) {
					padding: 32px 64px;
				}
			}
			

			> .v-expansion-panel-title {
				height: auto;
				min-height: 84px;
				padding: 16px;
				padding-right: 64px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.10);
			}
		}

		.FieldSet {

			.infoText,
			>.helpText {
				font-size: 12px;
				font-weight: 400;
				line-height: 16px;
				font-style: normal;
				letter-spacing: 0.001px;
				color: black;
			}

			>.FieldSet-label {
				font-size: 17px;
				font-weight: 500;
				line-height: 22px;
				letter-spacing: -0.071px;
				color: black;
			}

			.Field label.title {
				font-size: 15px;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: -0.037px;
				color: black;
			}
		}
	}

	.terminated-disclosure {
		> .v-expansion-panel > .v-expansion-panel-title {
			padding-left: 64px;
		}
	}

	.signing-disclosure {
		.v-expansion-panel {
			&-title {
				pointer-events: none;
				&__icon {
					display: none;
				}

				>div {
					flex: 1;

					&.column {
						display: none;
					}
				}

				.header-accessory {
					display: flex;
					flex-wrap: wrap;
					gap: 16px;
					align-items: center;

					>div:first-of-type {
						padding-left: 20px;
						display: flex;
						flex-direction: column;
						flex: 1;
						min-width: 200px;

						>span:first-child {
							font-size: 20px;
							font-weight: 600;
							line-height: 25px;
							letter-spacing: -0.196px;
							
							@media (max-width: 767px) {
								font-size: 18px;
								line-height: 22px;
							}
						}

						>span:last-child {
							font-size: 12px;
							font-weight: 500;
							line-height: 16px;
							letter-spacing: 0.001px;
							color: #8F8F8F
						}
					}

					>div:last-child {
						flex: 1;
						min-width: 200px;

						.info-text {
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 16px;
							letter-spacing: 0.001px;
						}

						.status-chip {
							display: flex;
							height: 24px;
							padding: 0px 8px;
							justify-content: center;
							align-items: center;
							gap: 8px;
							border-radius: 4px;
							font-size: 13px;
							font-weight: 400;
							line-height: 18px;
							letter-spacing: 0.001px;
							color: white;
							width: fit-content;
							margin-left: auto;
						}
					}
				}
			}
		}

		.contract-text,
		.contract-text p {
			font-size: 15px !important;
			line-height: 1.7;
			letter-spacing: -0.037px;
			color: black !important;
		}

		.v-input.v-checkbox {

			.v-label {
				padding-left: 16px;
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: -0.037px;
			}
		}

		.physical-title {
			>.FieldSet-label {
				font-size: 17px;
				font-weight: 600;
				line-height: 22px;
				letter-spacing: -0.071px;
				padding-bottom: 16px;
			}
		}

		.physical-text {
			font-size: 15px;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: -0.037px;
		}
	}


	.signing-sidebar {
		.sidebar {
			height: calc(100% - 64px) !important;
			overflow-y: auto !important;
			display: flex !important;
			flex-direction: column !important;
			
			.v-navigation-drawer__content {
				display: flex !important;
				flex-direction: column !important;
				height: 100% !important;
				
				> div:first-child {
					margin-bottom: 0px !important;
					display: flex !important;
					flex-direction: column !important;
					flex: 1 !important;
					min-height: 100% !important;
				}
			}
		}
	}

	.signing-sidebar-navigation {
		height: 100%;
		display: flex !important;
		flex-direction: column !important;
		
		.v-expansion-panel-title {
			min-height: 60px !important;
			display: block !important;
			font-size: 15px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: -0.037px;
			padding: 0 28px !important;
			position: relative;

			&__icon {
				position: absolute;
				top: calc(50% + 4px);
				transform: translateY(-50%);
				left: 5px;
			}
		}

		.v-expansion-panel-text__wrapper {
			padding: 0 !important;

			#navlist {
				li {
					padding-left: 28px;
					padding-right: 28px;
					list-style-type: none;
					width: 100%;
					align-items: middle;
					font-size: 15px;
					font-style: normal;
					font-weight: 400;
					line-height: 20px;
					letter-spacing: -0.037px;
					color: black;
					height: 60px;
					align-content: center;
					cursor: pointer;
					border: none;

					&.active {
						background-color: #00aeef;
						color: white !important;
						font-weight: 600;
					}
				}
			}
		}
	}

	// for some reason this doesn't work in the scoped style
	.contract-text ul {
		padding-left: 40px;
	}
}
</style>

<style lang="scss" scoped>
.ContractSigningView {
	@media print {
		.contract-information {
			margin: 0;
			padding: 0;
		}
	}

	.signing-sidebar {
		&.mobileSidebar {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;
		}

		.sidebar-title {
			font-size: 15px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: -0.037px;
		}

		.bottom-navigation {
			margin-top: auto;
			padding-bottom: 60px; /* Increased padding to avoid helper icon overlap */
			> div {
				padding-left: 28px;
				padding-right: 28px;
				width: 100%;
				font-size: 15px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
				letter-spacing: -0.037px;
				color: black;
				height: 60px;
				display: flex;
				align-items: center;
				cursor: pointer;
				border: none;
				&.active {
					background-color: #00aeef;
					color: white !important;
					font-weight: 600;
				}
			}
		}
	}
	
	.title {
		color: var(--primary-color-dark);
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 25px;
		letter-spacing: -0.196px;

		@media (max-width: 767px) {
			font-size: 18px;
			line-height: 22px;
		}
	}
}
</style>