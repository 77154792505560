<template>
	<div class="ContractsList">
		<div class="contracts-list">
			<v-expansion-panels variant="accordion">
				<v-expansion-panel v-for="(contract, index) of contracts" :key="index" class="contracts-list-item">
					<v-expansion-panel-title collapse-icon="mdi-menu-down" expand-icon="mdi-menu-right">
						<template v-slot:default>
							<div class="contracts-list-item-header">
								<v-icon style="margin: auto;" size="24px">mdi-gavel</v-icon>
								<div class="contracts-list-item-header-title">
									<span>{{ clientNames[contract.contractTemplate?.contractSequence?.clientId] }} | {{ issuedOnText(contract) }}</span>
									<span>{{ contract.contractTemplate?.name }}</span>
								</div>
								<v-spacer />
								<div class="contracts-list-item-header-info">
									<div>
										<div :style="{ 'background-color': getChipBackgroundColor(contract) }" class="contract-status-chip">
											<v-icon size="18px">{{ getChipIcon(contract) }}</v-icon>
											<span>{{ getChipStatusText(contract) }}</span>
										</div>
										<span :style="{color: getContractInfoTextColor(contract)}" v-html="getContractInfoText(contract)" />
									</div>
									<v-tooltip location="bottom" class="contracts-status-tooltip">
										<template #activator="{ props, isActive }">
											<v-icon :style="`color: ${isActive ? '#06aeef' : '#8F8F8F'}`" v-bind="props">mdi-information</v-icon>
										</template>
										<div class="contracts-status-tooltip-body">
											<span>{{ $t('text.contractStatusTooltipTitle') }}</span>
											<div v-for="status of contractStatuses" :key="status.title">
												<div>
													<div :style="{ 'background-color': status.color }" class="contract-status-chip">
														<v-icon size="18px">{{ status.icon }}</v-icon>
														<span>{{ status.title }}</span>
													</div>
												</div>
												<p v-html="status.text" />
											</div>
										</div>
									</v-tooltip>
								</div>
								<div style="padding-right: 10px; display: flex;">
									<v-icon v-if="terminated" style="margin: auto;" size="24px" @click.prevent.stop="downloadContract(contract)">mdi-download</v-icon>
									<v-menu v-else>
										<template v-slot:activator="{ props }">
											<v-icon style="margin: auto;" size="24px" v-bind="props" >mdi-dots-horizontal</v-icon>
										</template>
										<v-list class="contracts-list-item-header-menu">
											<v-list-item
												v-if="isSigned(contract)"
												:title="$t('text.downloadContractList')"
												prepend-icon="mdi-download"
												@click="downloadContract(contract)"
											/>
											<v-list-item 
												:title="$t('text.terminateContract')"
												prepend-icon="mdi-close-circle"
												@click="$emit('openTerminateContractDialog', contract)"
											/>
										</v-list>
									</v-menu>
								</div>
							</div>
						</template>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<ContractDetail :contract="contract" :show-contract-card="true"
							:contractor="getContractor(contract.contractor)" :terminated="terminated" 
						/>
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
		<template v-if="pdfExportLoading">
			<div ref="contractDetail">
				<ContractDetail :contract="contractForDownload" :pdf-export-loading="pdfExportLoading"
					:contractor="getContractor(contractForDownload.contractor)" :terminated="terminated" :show-contract-card="true"
				/>
			</div>
		</template>
	</div>
</template>
<script lang="ts">
import Common from '../../mixins/Common.vue'
import ContractDetail from './ContractDetail.vue'
import moment from 'moment'
import html2pdf from 'html2pdf.js'
import { Contract, Contractor, ContractStatus } from '../../../../api2/src/modules/contract/contract.entities'

export default {
	name: 'ContractsList',
	components: { ContractDetail },
	mixins: [ Common ],
	props: {
		contracts: { type: Array<Contract>, required: true },
		contractors: { type: Array<Contractor>, required: false, default: () => [] },
		clientNames: { type: Object, required: false, default: () => {} },
		contractor: { type: Contractor, required: false, default: null },
		terminated: { type: Boolean },
	},
	data: () => ({
		pdfExportLoading: false,
		contractForDownload: null as Contract | null,
	}),
	computed: {
		contractStatuses() {
			return [
				{
					title: this.$t('text.signed'),
					text: this.$t('text.signedStatusText'),
					color: '#64C823',
					icon: 'mdi-draw',
				},
				{
					title: this.$t('text.unsigned'),
					text: this.$t('text.unsignedWarningStatusText'),
					color: '#FFB400',
					icon: 'mdi-draw',
				},
				{
					title: this.$t('text.unsigned'),
					text: this.$t('text.unsignedErrorStatusText'),
					color: '#F34545',
					icon: 'mdi-draw',
				},
				{
					title: this.$t('text.terminated'),
					text: this.$t('text.terminatedStatusText'),
					color: '#8F8F8F',
					icon: 'mdi-close-circle',
				},
			]
		}
	},
	methods: {
		downloadContract(contract) {
			this.contractForDownload = contract
			this.pdfExportLoading = true
			const clientName = this.clientNames[contract.contractTemplate?.contractSequence?.clientId]
			const contractName = contract.contractTemplate.name
			const contractSignDate = contract.signedDate
			const contractTerminateDate = contract.endDate
			const isTerminatedContract = this.isTerminated(contract)
			const pdfName = `${clientName} ${contractName} ${isTerminatedContract ? ContractStatus.TERMINATED : ContractStatus.SIGNED} ${moment(isTerminatedContract  ? contractTerminateDate : contractSignDate).format('DD.MM.YYYY HH:mm')}.pdf`
			const options = {
				margin: 0.5,
				filename: pdfName,
				image: { type: 'jpeg', quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
				pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
			}
			this.$nextTick(() => {
				try {
					html2pdf().set(options).from(this.$refs.contractDetail).save();
				}
				catch (error) {
					this.showError(error.message)
				}
				finally {
					this.pdfExportLoading = false
				}
			})
		},
		getContractor(contractorId) {
			const foundContractor = this.contractors.find(x => x.id === contractorId)
			return foundContractor ?? this.contractor
		},
		issuedOnText(contract) {
			const createdDate = moment(contract.createdDate).format('DD.MM.YYYY')
			const createdTime = moment(contract.createdDate).format('HH:mm:ss')
			return `${ this.$t('text.issuedOn') } ${ createdDate } ${this.$t('text.at')} ${ createdTime }`
		},
		needsSignature(contract) {
			return contract?.contractTemplate?.needsSignature
		},
		isPhysical(contract) {
			return !this.needsSignature(contract)
		},
		isTerminated(contract) {
			return contract.endDate && moment(contract.endDate).isBefore(moment())
		},
		willBeTerminated(contract) {
			return contract.endDate && moment(contract.endDate).isAfter(moment())
		},
		isSigned(contract) {
			return !!contract.signedDate || contract.status == ContractStatus.SIGNED
		},
		toBeSigned(contract) {
			return !this.isSigned(contract) && (!contract.dueDate || (contract.dueDate && moment().isBefore(moment(contract.dueDate))))
		},
		overdue(contract) {
			return !this.isSigned(contract) && !this.toBeSigned(contract) && contract.dueDate && moment().isAfter(moment(contract.dueDate))
		},
		getChipBackgroundColor(contract) {
			if (this.isTerminated(contract)) {
				return '#8F8F8F'
			}
			if (this.isSigned(contract) || !this.needsSignature(contract)) {
				return '#64C823'
			}
			if (this.toBeSigned(contract)) {
				return '#FFB400'
			}
			if (this.overdue(contract)) {
				return '#F34545'
			}
			return '#8F8F8F'
		},
		getChipStatusText(contract) {
			if (this.isTerminated(contract)) {
				return this.$t('text.terminated')
			}
			if (this.isSigned(contract) || this.isPhysical(contract)) {
				return this.$t('text.signed')
			}
			return this.$t('text.unsigned')
		},
		getChipIcon(contract) {
			if (this.isTerminated(contract)) {
				return 'mdi-close-circle'
			}
			return 'mdi-draw'
		},
		getContractInfoText(contract) {
			if (this.willBeTerminated(contract)) {
				return `<b>${this.$t('text.willBeTerminatedOn')} ${moment(contract.endDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
					`${moment(contract.endDate).format('HH:mm:ss')}</b>`
			}
			if (this.isPhysical(contract)) {
				return `<b>${this.$t('text.noSignatureNeeded')}</b>`
			}
			if (this.isTerminated(contract)) {
				return `<b>${this.$t('text.terminatedOn')} ${moment(contract.endDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
					`${moment(contract.endDate).format('HH:mm:ss')}</b>`
			}
			if (this.isSigned(contract)) {
				return `${this.$t('text.signedOn')} <b>${moment(contract.signedDate).format('DD.MM.YYYY')} ${this.$t('text.at')} ` +
					`${moment(contract.signedDate).format('HH:mm:ss')}</b> by <b>${contract.signatureName}, ${contract.signaturePosition}</b>`
			}
			if (this.toBeSigned(contract)) {
				return `<b>${this.$t('text.signUntil')} ${moment(contract.dueDate).format('DD.MM.YYYY')}</b>`
			}
			if (this.overdue(contract)) {
				return `<b>${this.$t('text.notSignedInTime')}</b>`
			}
			return ''
		},
		getContractInfoTextColor(contract) {
			if(this.isTerminated(contract)) {
				return '#424242'
			}
			if (this.isSigned(contract) || !this.needsSignature(contract)) {
				return '#28500E'
			}
			if (this.toBeSigned(contract)) {
				return '#664800'
			}
			if (this.overdue(contract)) {
				return '#611C1C'
			}
			return '#DBDBDB'
		},
	}
}
</script>
<style lang="scss">
	.ContractsList {
		.contracts-list {
			margin-top: 16px;
			> .v-expansion-panels {
				> .contracts-list-item.v-expansion-panel {
					border: none !important;
					&:not(:last-child) {
						border-bottom: 1px solid #e5e5e5 !important;
					}
					border-radius: 0px;
					margin-bottom: 0px;
					&::after {
						display: none;
					}
					.v-expansion-panel__shadow {
						display: none !important;
					}
					.v-expansion-panel-title {
						justify-content: normal;
						height: auto;
						padding: 7px 12px;
						&__icon {
							order: 0;
							margin: 0px;
						}
						&:hover {
							background-color: #f9f9f9;
						}
					}
					.contracts-list-item-header {
						order: 1;
						padding-left: 6px;
						display: flex;
						width: 100%;
						&-title {
							display: flex;
							flex-direction: column;
							padding-left: 12px;

							> span:first-child {
								font-size: 12px;
								font-style: normal;
								font-weight: 400;
								line-height: 16px;
								letter-spacing: 0.001px;
								color: #8F8F8F;
							}
							> span:last-child {
								font-size: 15px;
								font-style: normal;
								font-weight: 400;
								line-height: 20px;
								letter-spacing: -0.037px;
							}
						}
						&-info {
							padding-right: 24px;
							display: flex;
							> div {
								text-align: end;
    							padding-right: 12px;
								> div {
									margin-left: auto;
								}
								> .v-icon {
									margin-left: 12px;
								}
								> span {
									font-size: 12px;
									line-height: 16px;
									letter-spacing: 0.001px;
									font-weight: normal;
								}
							}
						}
					}
				}
			}
		}
	}
	.contracts-list-item-header-menu {
		padding: 0;
		> .v-list-item {
			padding: 7px 12px;
			height: 60px;
			&:not(:last-child) {
				border-bottom: 1px solid #e5e5e5
			}
			.v-list-item__spacer {
				display: none;
			}
			.v-list-item__content {
				padding-left: 12px;
			}
			.v-list-item__prepend {
				color: black;
				.v-icon {
					opacity: 1;
				}
			}
		}
	}
	.contract-status-chip {
		padding: 0 8px;
		height: 24px;
		border-radius: 4px;
		color: white;
		font-size: 13px;
		font-weight: 400;
		line-height: 18px;
		letter-spacing: 0.001px;
		display: flex;
    	align-items: center;
		width: fit-content;
		> .v-icon {
			padding-right: 8px;
		}
	}
	.contracts-status-tooltip {
		.v-overlay__content {
			background-color: white;
			max-width: 510px !important;
			.contracts-status-tooltip-body {
				display: flex;
				flex-direction: column;
				> span {
					font-size: 15px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: -0.037px;
					color: black;
					padding-bottom: 12px;
				}
				> div {
					display: flex;
					padding-bottom: 8px;
					> div {
						display: flex;
						min-width: 130px;
					}
					> p {
						padding-left: 16px;
						font-size: 15px;
						font-weight: 400;
						line-height: 20px;
						letter-spacing: -0.037px;
						color: black;
					}
				}
			}
		}
	}
</style>