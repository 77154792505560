<template>
	<div class="Field" :class="{ focus, error: !!errors.length }"
		:data-type-id="typeName"
		:data-field-id="fieldName"
		:data-info-auto-key="typeName + '_' + fieldName + '_info'"
		:data-type="field?.type"
		:data-widget-id="field?.control?.widgetId"
		:data-is-right-side="isOnTheRightSide ? 'true' : undefined"
	>
		<FieldLayout :layout="field?.control?.layout">
			<template #descriptors>
				<div>
					<label v-if="showTitle" class="title" @click="$refs.field?.focus?.() ?? $emit('focus')">
						{{ title ?? name }}
						<span v-if="field?.type !== 'Boolean' && (field?.required || required)">&nbsp;({{ $t('text.required') }})</span>
					</label>
				</div>
				<LanguageFlag v-if="field?.localized || localized" v-model="locale" class="locale" />

				<div class="infoText" v-if="effectiveInfoText" v-html="effectiveInfoText"></div>
			</template>
			<template #controls>
				<template v-if="field">
					<template v-if="field.type == 'Symbol'">
						<TextField v-if="!field.control?.widgetId || field.control?.widgetId == 'singleLine'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<RadioField v-if="field.control?.widgetId == 'radio'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<!-- <div v-if="field.control?.widgetId == 'dropdown'">{{ model }}</div> -->
						<DropdownField v-if="field.control?.widgetId == 'dropdown'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<!--
						<SlugField v-if="field.control?.widgetId == 'slugEditor'" v-model="model" ref="field" :entry="entry" v-on="subHandlers" />
						<UrlField v-if="field.control?.widgetId == 'urlEditor'" v-model="model" ref="field" v-on="subHandlers" />
						-->
					</template>

					<template v-if="field.type == 'Text'">
						<TextField v-if="field.control?.widgetId == 'singleLine'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<LongTextField v-if="['multipleLine', 'markdown'].includes(field.control?.widgetId)" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'RichText'">
						<TiptapField v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Date'">
						<DateField v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Array' && field.items?.type == 'Link'">
						<!-- TODO: api2 version model should not be bound with .de -->
						<InlineEntryArrayField v-if="field.control?.widgetId == 'inlineEntryArray'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Array' && field.items.type == 'Symbol'">
						<TagsField v-if="field.control?.widgetId == 'tagEditor'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<MediasField v-if="field.control?.widgetId == 'mediaGallery'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<!--
						<CheckboxesField v-if="field.control.widgetId == 'checkbox'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						< !-- TODO: the listInput in CF is actually a comma separated text input -- >
						<TagsField v-if="field.control.widgetId == 'listInput'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						-->
					</template>

					<template v-if="field.type == 'Object'">
						<InlineEntryArrayField v-if="field.control?.widgetId == 'inlineEntryArray'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Link'">
						<!-- TODO: DropdownField currently does not really cater for the Link case!
									it should actually look at the given entries (should it resolve links?)
									and display their displayField  -->
						<DropdownField v-if="field.control?.widgetId == 'dropdown'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Number'">
						<NumberField v-if="!field.control?.widgetId || field.control?.widgetId == 'numberEditor'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Location'">
						<LocationField v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<template v-if="field.type == 'Boolean'">
						<SwitchField v-if="field.control?.widgetId == 'switch'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<BooleanField v-else v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>

					<!--
					<template v-if="field.type == 'Integer'">
						<NumberField v-if="!field.control?.widgetId || field.control?.widgetId == 'numberEditor'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<RatingField v-if="field.control?.widgetId == 'rating'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<DropdownField v-if="field.control?.widgetId == 'dropdown'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
						<RadioField v-if="field.control?.widgetId == 'radio'" v-model="model" ref="field" v-bind="subProps" v-on="subHandlers" />
					</template>
					-->
				</template>

				<slot></slot>
			</template>
			<template #validations>
				<!-- validation display -->
				<ul v-if="errors.length" class="errors">
					<li v-for="(error, e) of errors" :key="e" class="error">
						<svg class="icon-info" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>
						{{ $t('text.field-val-' + error.id, error?.params) }}
					</li>
				</ul>
			</template>
			<template #help>
				<div class="helpText" v-if="effectiveHelpText" v-html="effectiveHelpText"></div>
			</template>
		</FieldLayout>
	</div>
</template>

<script lang="ts">
import LanguageFlag from '../common/LanguageFlag.vue'
import ChildErrorDispatcher from '../../views/applications/packageDesigner/ChildErrorDispatcher.vue'

import TextField from './TextField.vue'
import RadioField from './RadioField.vue'
import LongTextField from './LongTextField.vue'
import TiptapField from './TiptapField.vue'
import InlineEntryArrayField from './InlineEntryArrayField.vue'
import DateField from './DateField.vue'
import TagsField from './TagsField.vue'
import NumberField from './NumberField.vue'
import DropdownField from './DropdownField.vue'
import LocationField from './LocationField.vue'
import BooleanField from './BooleanField.vue'
import SwitchField from './SwitchField.vue'
import MediasField from './MediasField.vue'
import { metadata } from '../../../../api2/src/framework/model-util'
import FieldLayout from './FieldLayout.vue'
/*
import CheckboxesField from './CheckboxesField.vue'
import SlugField from './SlugField.vue'
import UrlField from './UrlField.vue'
import RatingField from './RatingField.vue'
import LocationFieldOld from './LocationFieldOld.vue'
import JsonField from './JsonField.vue'
*/

// TODO: validate field immediately on mount
//       maybe ask container for this setting (like a "form setting")

export default {
	name: 'Field',
	mixins: [ ChildErrorDispatcher ],
	components: { FieldLayout, TextField, RadioField, LongTextField, TiptapField, LanguageFlag, InlineEntryArrayField, DateField, TagsField, NumberField, DropdownField, LocationField, BooleanField, MediasField, SwitchField },
	props: {
		modelValue: [ String, Number, Object, Array, Boolean ],
		// TODO: rename to typeId and fieldId
		typeName: String,
		fieldName: String,
		disabled: Boolean,
		dataCy: String,
		fieldLocale: String,
		locales: Array,

		// optional overrides
		title: String,
		required: { type: Boolean, default: undefined },
		localized: { type: Boolean, default: undefined },
		helpText: String,
		infoText: String,
		options: Array,
		showTitle: { type: Boolean, default: true },
		clearable: { type: Boolean, default: true },
		min: [ Number, String ],
		max: [ Number, String ],
		fieldOverride: Object,
		variant: String,
		placeholder: String,
		isOnTheRightSide: Boolean,
		watchRequired: Boolean
	},
	data: () => ({
		field: null as any,
		focus: false,
		errors: [],
		type: null,
		typeVersion: '0',
		debounceTimeout: null as any,
	}),
	computed: {
		locale() {
			if (this.fieldLocale) return this.fieldLocale
			if (this.localized === false) return 'de'
			if (this.localized != true && this.field?.localized === false) return 'de'
			return this.$store.state.serviceLocale
		},
		name() {
			if (!this.field) return null

			if (this.field.name.indexOf('{') > -1) {
				let result = this.field.name
				const textFields = result.match(/\{\w+\}/g)

				textFields.forEach(value => {
					result = result.replace(value, this.$t(`text.${value.slice(1, -1)}`))
				})

				return result
			}

			return this.$t('text.' + this.field.name)
		},
		effectiveInfoText() {
			return this.effectiveText('info', 'infoText')
		},
		effectiveHelpText() {
			return this.effectiveText('help', 'helpText')
		},
		effectivePlaceholder() {
			return this.effectiveText('placeholder')
		},
		subProps() {
			return {
				min: this.min,
				max: this.max,
				type: this.type,
				field: this.field,
				i18nModel: this.field?.localized ? this.modelValue : null,
				disabled: this.disabled,
				dataCy: this.dataCy ?? this.fieldName,
				locale: this.locale,
				locales: this.locales,
				options: this.options,
				clearable: this.clearable,
				variant: this.variant,
				placeholder: this.effectivePlaceholder,
				fieldName: this.fieldName,
				typeName: this.typeName,
				isOnTheRightSide: this.isOnTheRightSide,
			}
		},
		subHandlers() {
			return {
				focus: this.onFocus,
				blur: this.onBlur,
				errors: this.onErrors,
				changed: (n) => { this.model = n },
				'update:i18nModel': (n) => { this.model = n },
			}
		},
		model: {
			get() {
				if (this.typeVersion >= '2.0' && !this.field?.localized)
					return this.modelValue
				return this.modelValue?.[ this.locale ]
			},
			set(n) {
				if (this.debounceTimeout) window.clearTimeout(this.debounceTimeout)
				this.debounceTimeout = window.setTimeout(() => {
					if (this.typeVersion >= '2.0' && !this.field?.localized) {
						this.$emit('update:modelValue', n)
						return
					}

					if (!this.modelValue) {
						this.$emit('update:modelValue', { [this.locale]: n })
					} else {
						this.modelValue[this.locale] = n
						this.$emit('update:modelValue', this.modelValue)
					}
				}, 50)
			},
		},
	},
	watch: {
		required () {
			if (this.required !== undefined && this.watchRequired) {
				this.field.required = this.required
				if (this.$refs.field) {
					this.$refs.field.validate()
				}
			}
		},
	},
	methods: {
		onFocus() {
			this.focus = true
		},
		onBlur(e) {
			this.focus = false
			this.$emit('blur', e)
		},
		onErrors(errors) {
			//console.log('FieldWrapper.onErrors', errors)
			this.errors = errors
			// log the field for debugging
			// console.log('FieldWrapper.onErrors', this.field)
			// Special case for SBB: if the field is a content field, we do not show any validation errors
			this.childErrorAutoDispatch(this.$el, errors)
		},
		effectiveText(keySuffix: string, prop: string | null = null, controlSettingsProp: string | null = null) {
			if (!prop) prop = keySuffix
			if (!controlSettingsProp) controlSettingsProp = prop

			// if the field instance explicitely defines a value we use that with highest priority
			if (this[prop]) return this[prop]

			// if the Model explicitely defines a value we use that
			if (this.field?.control?.settings?.[controlSettingsProp]) return this.$t('text.' + this.field.control.settings[controlSettingsProp])

			// we magically allow the admin to enter texts for text.myType_myField_info / text.myField_info
			let t
			t = this.$t('text.' + this.typeName + '_' + this.fieldName + '_' + keySuffix)
			if (!t?.startsWith('text.')) return t
			t = this.$t('text.' + this.fieldName + '_' + keySuffix)
			if (!t?.startsWith('text.')) return t
		},
		checkMissingModel() {
			return this.model == undefined && this.typeVersion < '2.0'
				|| this.model == undefined && this.typeVersion >= '2.0' && this.field?.localized
		},
	},
	mounted() {

		if (this.typeName) {
			// TODO: inject the models instead of taking them from the store
			let type
			if (!type) type = metadata?.[this.typeName]
			if (!type) type = this.$store.state.fieldModels.find(e => e?.name === this.typeName)
			if (!type) return console.warn('Field: type not found [' +  this.typeName + ']')
			this.typeVersion = type?.version ?? '0'
			this.field = type?.fields[this.fieldName]
			if (!this.field) return console.warn('Field: field not found [' + this.typeName + '.' + this.fieldName + ']')
			if (this.required !== undefined) this.field.required = this.required
			this.type = this.field.type
			if (this.checkMissingModel()) {
				// TODO: at one point this worked nicely, but since we introduced TranslateableField i always get this error on FAQs..
				console.warn('Field: model is undefined for [' + this.typeName + '.' + this.fieldName + ']')
			}
		}

		if (this.checkMissingModel()) {
			// TODO: check if we might actually need this - currently i have removed it because this breaks the FAQs open-binding of q+a
			//console.log('Field: setting model to null for [' + this.typeName + '.' + this.fieldName + ']')
			//this.$emit('update:modelValue', { de: null })
		}

		if (this.fieldOverride) {
			this.field = this.fieldOverride
		}
	},
}
</script>

<style scoped>
.Field {
	--col-title: #000000;
	--col-line: #cfd9e0;
	--col-line-focus: #036fe3;
/*	--col-error: #da294a;*/
	position: relative;
}

.Field { padding-left: 1em; border-left: 3px solid var(--col-line); transition: border-left-color 0.25s ease-out; margin-bottom: 16px; }
.Field.focus { border-left: 3px solid var(--col-line-focus); }
.Field.error { border-left-color: var(--col-error); }

.title { color: var(--col-title); display: -webkit-box; display: -ms-flexbox; display: flex; font-size: 14px; line-height: 24px; max-width: 800px; width: 100%; }

.errors { font-size: 12px; font-weight: 600; line-height: 16px; margin-top: 4px; padding: 0px; overflow-wrap: break-word; color: var(--col-error); list-style-type: none; }
.errors .error { list-style-type: none; margin-bottom: 5px; display: flex; }

.infoText,
.helpText { font-size: 12px; line-height: 16px; color: var(--col-title); width: 100%; margin-bottom: 4px; }

.icon-info { display: inline-block; fill: var(--col-error); height: 18px; width: 18px; margin-right: 5px; }
.locale { position: absolute; margin-top: 15px !important; margin-left: -32px !important; zoom: 0.8; }

/* Only apply padding when switch is on the right side. This prevents the switch from overflowing the text, especially on smaller windows. */
[data-type="Boolean"][data-is-right-side="true"] .infoText {
	padding-right: 40px;
	margin-top: 4px;
	width: calc(100% - 40px);
}
</style>

<style>
.Field .input {
	width: 100%;
	outline: none;
	box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset;
	box-sizing: border-box;
	background-color: white;
	border: 1px solid var(--col-line);
	border-radius: 6px;
	color: rgb(65, 77, 99);
	font-size: 0.875rem;
	line-height: 1.25rem;
	padding: 10px 0.75rem;
	margin: 0px;
	cursor: auto;
	width: 100%;
	height: 40px;
	max-height: 40px;
	vertical-align: middle;
}

.Field .error > .input { border: 1px solid var(--col-error); }
.Field .input:focus { border-color: rgb(0, 89, 200); box-shadow: rgb(152 203 255) 0px 0px 0px 3px; }
.Field .error>.input:focus { border-color: var(--col-error); box-shadow: rgb(255 177 178) 0px 0px 0px 3px; }

/* if we are inside a fieldset, we dont show the border */
.FieldSet .Field { border-left: none; padding-left: 0; }
.FieldSet .Field.focus { border-left: none; }

.Field .TextField > .input-wrapper,
.Field .LongTextField > .input-wrapper,
.Field .TiptapField > .input-wrapper {
	position: relative;
}
</style>