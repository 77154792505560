<template>
	<div v-if="hasField('startAdvertisingDate') || hasField('leadTime')" class="LeadTime">
		<Disclosure ref="SECTION_publishingAndLeadtime" :title="disclosureTitle" :lock="!userMayEditFields || productIsExternal" data-cy="leadTime" :info="hasTicket && !productAvailabilities.length" :message="hasTicket && !productAvailabilities.length ? $t('text.availabilityNeedsToBeConfigured') : ''">
		<template v-if="model">
			<FieldSet v-if="hasField('startAdvertisingDate')" :label="$t('text.advertiseService')" :info-text="$t('text.advertiseServiceInfoText')">
				<div class="fieldTitleContainer">
					<p class="fieldTitle">{{ $t('text.publishToOnlineStore') }}</p>
					<p class="helpText">{{ $t('text.publishToOnlineStoreInfo') }}</p>
				</div>
				<div class="Field"><VuetifyDatePicker v-model="model.fields.startAdvertisingDate.de" dataCy="startAdvertisingDate" :placeholder="$t('text.publishingDate')" /></div>
			</FieldSet>
			<FieldSet v-if="hasTicket && hasField('leadTime')" :label="$t('text.bookingLeadTimeConfiguration')" :info-text="$t('text.bookingLeadTimeConfigurationInfoText')" class="leadTimePreviewAndSelector">
				<div>
					<LeadTimeSelector :modelValue="leadTimeModel" @update:modelValue="setLeadTimes" />
					<LeadTimePreview ref="leadTimePreview" :lead-time="leadTimeModel" :product-availabilities="productAvailabilities" />
				</div>
			</FieldSet>
		</template>
	</Disclosure>
	</div>
</template>

<script>
import Disclosure from '@/components/common/Disclosure.vue'
import Common from "@/mixins/Common.vue"
import FieldSet from '../../views/applications/packageDesigner/FieldSet.vue'
import Field from '../fields/Field.vue'
import VuetifyDatePicker from "@/components/datePicker/VuetifyDatePicker.vue"
import LeadTimeSelector from './LeadTimeSelector.vue'
import LeadTimePreview from './LeadTimePreview.vue'

export default {
	name: 'LeadTime',
	components: { Disclosure, FieldSet, Field, VuetifyDatePicker, LeadTimeSelector, LeadTimePreview },
	mixins: [ Common ],
	props: {
		product: Object,
		productAvailabilities: Array,
		productIsExternal: Boolean,
		hasTicket: Boolean
	},
	data: () => ({
		model: null
	}),
	watch: {
		productAvailabilities() {
			this.$refs.leadTimePreview?.resetValues()
		}
	},
	computed: {
		disclosureTitle() {
			return this.hasTicket ? this.$t('text.publishingAndLeadtime') : this.$t('text.publishing')
		},
		leadTimeModel() {
			return {
				leadTimeDays: this.model?.fields?.leadTimeDays?.de ?? 0,
				leadTimeMonths: this.model?.fields?.leadTimeMonths?.de ?? 0,
				leadTimeWeeks: this.model?.fields?.leadTimeWeeks?.de ?? 0,
				leadTimeHours: this.model?.fields?.leadTimeHours?.de ?? 0
			}
		}
	},
	methods: {
		hasField(fieldName) {
			return this.model.fields?.serviceType?.de?.fields?.template?.de?.availabilityInfo?.[fieldName]
		},
		setLeadTimes(value) {
			this.model.fields.leadTimeDays.de = value.leadTimeDays
			this.model.fields.leadTimeMonths.de = value.leadTimeMonths
			this.model.fields.leadTimeWeeks.de = value.leadTimeWeeks
			this.model.fields.leadTimeHours.de = value.leadTimeHours
		},
		sendData() {
			const data = {}
			if (this.hasField('startAdvertisingDate')) {
				data.startAdvertisingDate = this.model.fields.startAdvertisingDate
			}

			if (this.hasField('leadTime')) {
				data.leadTimeMonths = this.model.fields.leadTimeMonths
				data.leadTimeWeeks = this.model.fields.leadTimeWeeks
				data.leadTimeDays = this.model.fields.leadTimeDays
				data.leadTimeHours = this.model.fields.leadTimeHours
			}
			return data
		}
	},
	created() {
		this.model = JSON.parse(JSON.stringify(this.product ?? {}))
	}
}
</script>
<style scoped lang="scss">
	.LeadTime {
		::v-deep .FieldSet {
			&-label {
				font-size: 17px;
				font-weight: 500;
				line-height: 22px;
				letter-spacing: -0.071px;
				color: black;
			}

			> .Field {
				padding-top: 4px;
				width: 169px;
			}
		}

		::v-deep .fieldTitleContainer {
			padding-top: 16px;
			.fieldTitle {
				font-size: 15px;
				font-weight: 500;
				line-height: 20px;
				letter-spacing: -0.037px;
				color: black;
			}
		}

		::v-deep .helpText {
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			letter-spacing: 0.001px;
			color: black;
			padding-top: 4px;
		}

		.leadTimePreviewAndSelector {
			margin-top: 32px;
			> div {
				padding-top: 24px;
				display: flex;
				gap: 24px;
				.LeadTimePreview,
				.LeadTimeSelector {
					width: 100%;
					::v-deep .fieldTitleContainer {
						padding-top: 0;
						padding-bottom: 12px;
						> div {
							display: flex;
							> i {
								padding-left: 16px;
							}
						}
					}

					::v-deep .customContainer {
						.fieldTitleContainer {
							padding-top: 12px;
							padding-bottom: 4px;
						}
						.customContainerFields {
							display: flex;
							gap: 12px;
							> .Field {
								width: 100%;
								margin: 0;
								> div {
									width: 100% !important;
								}
							}
						}
					}
					::v-deep .eventDateInfos {
						padding-top: 12px;
						> div {
							font-size: 15px;
							font-weight: 400;
							line-height: 20px;
							letter-spacing: -0.037px;
						
							span {
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
</style>