<template>
	<div style="display: flex; gap: 10px; flex-direction: column; width: 320px;">
		<DatePickerCalendar
			class="IsoDatePicker"
			v-model="dateModel"
			:disabled="disabled"
		/>
		<div style="display: flex; gap: 10px; align-items: center; padding: 0 20px 20px 20px;" v-if="time">
			<label style="flex-grow: 1;">{{ $t('text.time') }}</label>
			<v-select variant="outlined" hide-details density="compact" 
				style="max-width: 120px;"
				v-model="timeModel"
				:items="times"
			/>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import DatePickerCalendar from '../../views/applications/packageDesigner/DatePickerCalendar.vue'

// TODO: timezone handling??
//         picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

export default {
	components: { DatePickerCalendar },
	props: {
		modelValue: String,
		disabled: { type: Boolean, default: false },
		// ASSUMPTION: this timezone is relative to the services local timezone
		// TODO: we will have to add a timezone field to the service and to the service provider and pass it in here
		// TODO: where to get the timezone offset of the service from?
		// TODO: what about DST?
		//tz: { type: String, default: 'America/Los_Angeles' },
		// TODO" not sure if timezone is fully supported in this component. Needs testing.
		// TODO: warn about mismatch in TZ
		tz: { type: String, default: 'Europe/Vienna' },
		time: Boolean,
	},
	data: () => ({
		dateModel: null,
		timeModel: '00:00',
	}),
	computed: {
		times() {
			const r = []
			for (let h = 0; h < 24; h++) {
				for (let m = 0; m < 60; m += 30) {
					let hh = h.toString().padStart(2, '0')
					let mm = m.toString().padStart(2, '0')
					r.push(`${hh}:${mm}`)
				}
			}
			return r
		},
	},
	watch: {
		// iso string at target tz -> local Date
		modelValue(n) {
			this.input(n)
			return

			// the date coming in is an iso date string. it will have a time zone attached, that should match this.tz
			//console.log('IsoDatePicker modelValue 1', n, typeof n, 'tz', this.tz)
			if (!n) return new Date().setHours(0, 0, 0)
			n = moment(n.substring(0, 10)).toDate()
			//console.log('IsoDatePicker modelValue 2', n, typeof n)
			this.model = n//.toISOString().substring(0, 10)
		},
		dateModel(n) {
			this.output()
		},
		timeModel(n) {
			this.output()
		},
	},
	methods: {
		input() {
			console.log('input', this.modelValue)
			if (!this.modelValue) return
			let n = this.modelValue
			this.dateModel = moment(n).format('YYYY-MM-DD')
			if (this.time) this.timeModel = moment(n).format('HH:mm')
		},
		// local Date -> iso string at target tz
		output() {
			console.log('output')
			let n = this.dateModel
			// the date coming in is in browser timezone like Sun Jun 11 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
			// we need to bring it into the services time zone - in our case this is easy, as we dont have time, only date
			n = moment.tz(n, this.tz).format('YYYY-MM-DD')
			// n = moment.tz(n, this.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			if (this.time) n += 'T' + this.timeModel
			this.$emit('update:modelValue', n)
		}
	},
	beforeMount() {
		this.input()
	},
}
</script>