<template>
	<div class="LeadTimePreview">
		<div class="fieldTitleContainer">
			<div>
				<p class="fieldTitle">{{ $t('text.leadTimePreview') }}</p>
				<HelpIcon keyPrefix="leadtime_preview" :whiteBackground="true" />
			</div>
			<p class="helpText">{{ $t('text.leadTimePreviewInfo') }}</p>
		</div>
		<div class="customContainer">
			<div class="customContainerFields">
				<div class="Field"><RadioSelect v-model="availabilityModel" :options="productAvailabilitiesDropdownValues" :celarable="true" :useRawValue="true" :placeholder="$t('text.selectAvailability')" :disabled="emptyProductAvailabilities" /></div>
				<div class="Field"><VuetifyDatePicker v-model="dateModel" :min="availabilityValue?.validFromDate" :max="availabilityValue?.validToDate" :placeholder="$t('text.selectDate')" :disabled="emptyProductAvailabilities || !availabilityModel" :disabledDates="disabledDates" :initDate="initDate" /></div>
				<div class="Field"><RadioSelect v-model="timeModel" :options="timeDropdownValues" :celarable="true" :useRawValue="true" :placeholder="$t('text.selectTime')" :disabled="emptyProductAvailabilities || !availabilityModel || !availabilityModel || !timeDropdownValues?.length" /></div>
			</div>
		</div>
		<div v-if="eventDate && firstAvailableDate" class="eventDateInfos">
			<div><span>{{ $t('text.eventOn') }}</span> {{ eventDate }}</div>
			<div>{{ $t('text.latestPossibleBookingOn') }} {{ firstAvailableDate }}</div>
		</div>
	</div>
</template>

<script>
import Common from "@/mixins/Common.vue"
import RadioSelect from '../common/RadioSelect.vue'
import HelpIcon from '../common/HelpIcon.vue'
import VuetifyDatePicker from "@/components/datePicker/VuetifyDatePicker.vue"
import moment from 'moment'

export default {
	name: 'LeadTimePreview',
	components: { RadioSelect, HelpIcon, VuetifyDatePicker },
	mixins: [ Common ],
	props: {
		leadTime: Object,
		productAvailabilities: Array
	},
	data: () => ({
		dummyFieldDefinition: { validations: [] },
		availabilityModel: null,
		dateModel: null,
		timeModel: null
	}),
	watch: {
		availabilityValue(val) {
			if (!val) {
				this.resetValues()
			}
		}
	},
	computed: {
		productAvailabilitiesDropdownValues() {
			return this.productAvailabilities
			.filter(x => {
				const from = moment(x.validFromDate)
				const to = moment(x.validToDate)
				const today = moment()

				return today.isBetween(from, to, null, '[]') || from.isAfter(today)
			})
			.map(x => ({
				title: `${moment(x.validFromDate).format('DD.MM.YYYY')} - ${moment(x.validToDate).format('DD.MM.YYYY')}`,
				value: x
			}))
		},
		timeDropdownValues() {
			return this.availabilityValue?.timeSlots
			.map(x => ({
				title: x,
				value: x
			}))
		},
		emptyProductAvailabilities() {
			return !this.productAvailabilitiesDropdownValues?.length
		},
		availabilityValue() {
			return this.availabilityModel?.value
		},
		timeModelValue() {
			return this.timeModel?.value
		},
		dateModelValue() {
			return this.dateModel ? moment(this.dateModel).format('YYYY-MM-DD') : null
		},
		disabledDates() {
			if (this.availabilityValue && this.availabilityValue.exceptions?.length) {
				const fromDate = this.availabilityValue.validFromDate
				const toDate = this.availabilityValue.validToDate

				const excludedWeekdays = new Set(
					this.availabilityValue.exceptions.filter(v => moment().day(v).isValid())
				)
				const explicitlyIncludedDates = new Set(
					this.availabilityValue.exceptions.filter(v => moment(v, 'YYYY-MM-DD', true).isValid())
				)

				const result = []
				let current = moment(fromDate)
				const end = moment(toDate)
				const today = moment().startOf('day')
				while (current.isSameOrBefore(end)) {
					const weekday = current.format('dddd')
					const dateStr = current.format('YYYY-MM-DD')
					if (current.isBefore(today) || !excludedWeekdays.has(weekday) || explicitlyIncludedDates.has(dateStr)) {
						result.push(dateStr)
					}

					current.add(1, 'day')
				}
				return result
			}
			return null
		},
		eventDate() {
			return this.dateModelValue || this.initDate ? `${moment(this.dateModelValue ?? this.initDate).format('DD.MM.YYYY')}${this.timeModelValue ? ` ${this.timeModelValue}:00` : ''}` : null
		},
		initDate() {
			if (this.availabilityValue) {
				let date = moment(`${this.dateModelValue ? this.dateModelValue : this.availabilityValue.validFromDate}${this.timeModelValue ? ` ${this.timeModelValue}` : ''}`)
				const disabledSet = new Set(this.disabledDates)
				if (disabledSet.size) {
					let parsed = date.format('YYYY-MM-DD')
					while (disabledSet.has(parsed)) {
						date = date.add(1, 'd')
						parsed = date.format('YYYY-MM-DD')
					}
				}
				return date
			}
			return null
		},
		firstAvailableDate() {
			const leadTimeMonth = this.leadTime?.leadTimeMonths
			const leadTimeWeek = this.leadTime?.leadTimeWeeks
			const leadTimeDay = this.leadTime?.leadTimeDays
			const leadTimeHour = this.leadTime?.leadTimeHours
			if (this.initDate) {
				let date = this.initDate.clone()
				if (leadTimeMonth > 0) {
					date.add(leadTimeMonth * -1, 'M')
				}
				if (leadTimeWeek > 0) {
					date.add(leadTimeWeek * -1, 'w')
				}
				if (leadTimeDay > 0) {
					date.add(leadTimeDay * -1, 'd')
				}
				if (leadTimeHour > 0) {
					date.add(leadTimeHour * -1, 'h')
				}
				return date.add(-1, 's').format('DD.MM.YYYY HH:mm:ss')
			}
			return null
		}
	},
	methods: {
		resetValues() {
			this.availabilityModel = null
			this.dateModel = null
			this.timeModel = null
		},
	},
}
</script>
<style scoped>
	
</style>