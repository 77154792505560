<template>
	<Disclosure :title="$t('text.availabilityInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
		:lock="!userMayEditFields || productIsExternal" data-cy="availabilityInfo" ref="SECTION_availabilityInfo"
	>

		<template v-if="!featureEnabled('new-lead-times')">
			<div class="field left-border" v-if="hasField('startAdvertisingDate')"
				:class="{ disabled }"
			>
			<DatePicker
			v-model="model.fields.startAdvertisingDate.de"
			dataCy="startAdvertisingDate"
			>
			<!-- <IsoDatePicker v-model="model.fields.startAdvertisingDate.de" tz="Europe/Vienna" style="width: 100%; flex-grow: 1;" /> -->

			<template #label>{{ $t('text.startAdvertisingDate') }}</template>
			<template #helperText>
				<p class="helpText">{{ $t('text.startAdvertisingDateInfo') }}</p>
			</template>
			</DatePicker>
			</div>

			<div class="field left-border" v-if="hasField('leadTime')">
				<v-label>{{ $t('text.leadTime') }}</v-label>
				<div class="col4 mt-4">
					<div>
						<v-label>{{ $t('text.months') }}</v-label>
						<v-select variant="outlined" density="compact" hide-details
							data-cy="leadTimeMonths"
							v-model="model.fields.leadTimeMonths.de"
							:items="cbMonths"
						/>
					</div>
					<div>
						<v-label>{{ $t('text.weeks') }}</v-label>
						<v-select variant="outlined" density="compact" hide-details
							data-cy="leadTimeWeeks"
							v-model="model.fields.leadTimeWeeks.de"
							:items="cbWeeks"
						/>
					</div>
					<div>
						<v-label>{{ $t('text.days') }}</v-label>
						<v-select variant="outlined" density="compact" hide-details
							data-cy="leadTimeDays"
							v-model="model.fields.leadTimeDays.de"
							:items="cbDays"
						/>
					</div>
					<div>
						<v-label>{{ $t('text.hours') }}</v-label>
						<v-select variant="outlined" density="compact" hide-details
							data-cy="leadTimeHours"
							v-model="model.fields.leadTimeHours.de"
							:items="cbHours"
						/>
					</div>
				</div>
				<p class="helpText" v-html="$t('text.leadTimeHelp')"/>
			</div>
		</template>

		<div>
			<v-label>{{ $t('text.availabilityContingentTitle') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
			<p class="helpText" v-html="$t('text.availabilityContingentDesc')" />
		</div>

		<div v-if="hasField('productAvailabilities')" style="display: flex; flex-direction: column; gap: 10px; padding-top: 10px;">
			<div class="availability panel" v-for="(availability, availabilityIndex) in model.fields.productAvailabilities.de"
				:key="availability.sys.id"
			>
				<v-btn v-if="model.fields.productAvailabilities.de.length > 1"
					id="btnRemoveAvailabilityRow"
					class="removeRow"
					@click="removeAvailabilityRow(availabilityIndex)"
				>
					<v-icon color="white">mdi-delete</v-icon>
				</v-btn>
				<div v-if="csError && csError.data.availabilityId === availability.sys.id" class="error-container">
					<p id="errors" v-html="formatCSErrors"/>
				</div>
				<div>
					<div class="col2">
						<div>
							<DatePicker v-if="availability.fields.validFromDate"
								v-model="availability.fields.validFromDate.de"
								:errors="validFromToError(availability, 'validFromDate')"
								dataCy="validFromDate"
							>
								<template #label>{{ $t('text.validFrom') }} <span class="error-text">({{ $t('text.required') }})</span></template>
							</DatePicker>
						</div>
						<div>
							<DatePicker v-if="availability.fields.validToDate"
								v-model="availability.fields.validToDate.de"
								:min="availability.fields.validFromDate.de"
								:errors="validFromToError(availability, 'validToDate')"
								dataCy="validToDate"
							>
								<template #label>{{ $t('text.validUntil') }} <span class="error-text">({{ $t('text.required') }})</span></template>
							</DatePicker>
						</div>
					</div>

					<p class="helpText" v-html="$t('text.validDatesDesc')" v-if="availabilityIndex == 0" />
					<div class="col2 mt-4" v-if="hasField('productContingents')">
						<div>
							<v-label>{{ $t('text.maxContingent') }} <span class="error-text">({{ $t('text.required') }})</span></v-label>
						</div>
						<div>
							<v-label>{{ $t('text.time') }}</v-label>
						</div>
					</div>
					<div v-if="hasField('productContingents')">
						<div v-for="(contingent, contingentIndex) in availability.fields.productContingents.de" :key="contingent.sys.id"
							class="col2"
						>
							<div>
								<v-text-field variant="outlined" density="compact" hide-details
									data-cy="maximumContingent"
									type="number"
									:min="contingent.fields.maximumContingent.de"
									v-model="contingent.fields.maximumContingent.de"
									:error-messages="contingentError(contingent)"
									style="background: white;"
								/>
							</div>
							<div>
								<div style="display: flex; gap: 10px; align-items: flex-end;">
									<div style="flex-grow: 1;">
										<!-- show a red color around the field if the timeslotError length is bigger than 0 -->
										<v-text-field variant="outlined" density="compact" hide-details
											:error-messages="timeSlotError(contingent)"
											data-cy="timeSlot"
											v-mask="'##:##'"
											placeholder="00:00"
											v-model="contingent.fields.timeSlot.de"
											@blur="contingent.fields.timeSlot.de = fixTime(contingent.fields.timeSlot.de)"
											style="background: white;"
										/>
									</div>
									<div style="max-width: 50px;">
										<v-btn size="small" style="height: 44px !important;" id="btnAddContingentRow" class="gradientButton" elevation="0" v-if="contingentIndex === 0"
											@click="addContingentRow(availabilityIndex)"
										>
											<v-icon size="24px">mdi-plus</v-icon>
										</v-btn>
										<v-btn size="small" style="height: 44px !important;" id="btnRemoveContingentRow" class="gradientButton" elevation="0" v-if="contingentIndex !== 0"
											@click="removeContingentRow(availabilityIndex, contingentIndex)"
										>
											<v-icon size="24px" color="#fb3640">mdi-delete</v-icon>
										</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="helpText" v-html="$t('text.contingentsHelp')" v-if="availabilityIndex == 0 && serviceWithTicket" />
				<p v-if="serviceWithTicket" class="helpText red-text" v-html="$t('text.timeslotHelp')" />

				<div>
					<AvailabilityExceptionSelector
						v-model="availability.fields.exceptions.de"
						:min="availability.fields.validFromDate.de"
						:max="availability.fields.validToDate.de"
						data-cy="exceptions"
					/>
				</div>

				<!--
				<p class="helpText mb-2" v-html="$t('text.additionalDateSettingsHelp')" v-if="availabilityIndex == 0" />
				-->

				<!--
				<v-btn id="btnRemoveAvailabilityRow" class="gradientButton" elevation="0"
					@click="removeAvailabilityRow(availabilityIndex)"
				>
					<v-icon size="24px" color="#fb3640">mdi-delete</v-icon>
					{{ $t('text.deleteTimeframe') }}
				</v-btn>
				-->
			</div>
			<button id="btnAddAvailabilityRow" class="addPanel" elevation="0"
				style="height:40px;font-size:12pt;margin-right:10px;margin-top:15px;" @click="addAvailabilityRow()"
			>
				<v-icon size="24px">mdi-plus</v-icon>
				{{ $t('text.addTimeframe') }}
			</button>
		</div>

		<ContingentCalendarNew v-if="featureEnabled('contingent-monitor')" ref="contingentCalendar"
			:availabilities="model.fields.productAvailabilities.de" :product="product"
			style="margin-top: 20px;"
		/>
	</Disclosure>
</template>

<script>
import AvailabilityExceptionSelector from './AvailabilityExceptionSelector.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Common from "@/mixins/Common.vue"
import isEqual from 'lodash/isEqual'
import DatePicker from "@/components/datePicker/DatePicker.vue"
import ContingentCalendarNew from './contingentMonitor/ContingentCalendarNew.vue'
import IfFeatureFlag from '../../components/ifFeatureFlag/IfFeatureFlag.vue'

export default {
	name: 'Availability',
	components: { AvailabilityExceptionSelector, Disclosure, DatePicker, ContingentCalendarNew, IfFeatureFlag },
	mixins: [ Common ],
	props: {
		product: Object,
		updateModel: Boolean,
		disabled: Boolean,
		productIsExternal: Boolean,
		csError: Object,
		csDeletedValidityError: Boolean
	},
	data: () => ({
		model: {},
		initData: {},
		isNewProduct: false,
		fromDate: null,
		toDate: null,
		counter: 0,
		contingentProblem: false,
		originalAvailabilities: [],
		cbMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
		cbWeeks: [0, 1, 2, 3],
		cbDays: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
		cbHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
		sectionMessage: {
			error: false,
			message: ''
		},
		daysOfWeek: [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ],
		timeSlotProblem: false,
	}),
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.product)
			this.setAvailabilityDetails()
			this.setInitData()
		},
		productAvailabilities: {
			deep: true,
			handler() {
				if (!this.productAvailabilities?.length) {
					this.$emit('setRefactoredProductAvailabilities', [])
					return
				}
				const availabilities = this.productAvailabilities
					.map(({ fields }) => {
						const validFromDate = fields?.validFromDate?.de
						const validToDate = fields?.validToDate?.de

						if (!validFromDate || !validToDate) return null

						const timeSlots = fields?.productContingents?.de
							?.filter(x => x.fields?.timeSlot?.de && x.fields.timeSlot.de.split(':').length === 2)
							?.map(x => x.fields.timeSlot.de)

						return { validFromDate, validToDate, timeSlots, exceptions: fields?.exceptions?.de }
					})
					.filter(Boolean)
				this.$emit('setRefactoredProductAvailabilities', availabilities)
			}
		}
	},
	computed: {
		productAvailabilities() {
			return this.model?.fields?.productAvailabilities?.de
		},
		productWasOnceApproved() {
			let clientAssignment = this.model?.fields?.clientAssignments?.de.find(assignment => assignment.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
			return !(!clientAssignment?.fields?.approvalDate || clientAssignment?.fields?.approvalDate?.de === "")
		},
		formatCSErrors() {
			if (!this.csError) return ''

			let formattedErrors = ''
			let errors = this.csError?.messageForEndUser[this.selectedLocale].split("\n")

			for (const error of errors) {
				if (error.length) {
					formattedErrors += `${error}<br/>`
				}
			}

			return formattedErrors
		},
		serviceWithTicket() {
			return this.model?.fields?.serviceType?.de?.sys?.id === 'SERVICE-TYPE-EVENT-1' || this.$store.state.selectedServiceType?.sys?.id === 'SERVICE-TYPE-EVENT-1'
		}
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))

			if (this.hasField('startAdvertisingDate')) {
				this.initData.startAdvertisingDate = initModel.fields.startAdvertisingDate
			}

			if (this.hasField('productAvailabilities')) {
				this.initData.productAvailabilities = initModel.fields.productAvailabilities
			}

			if (this.hasField('leadTime')) {
				this.initData.leadTimeMonths = initModel.fields.leadTimeMonths
				this.initData.leadTimeWeeks = initModel.fields.leadTimeWeeks
				this.initData.leadTimeDays = initModel.fields.leadTimeDays
				this.initData.leadTimeHours = initModel.fields.leadTimeHours
			}

			this.$refs.contingentCalendar?.load?.()
		},
		sendData() {
			let data = {}

			if (this.hasField('startAdvertisingDate') && !this.featureEnabled('new-lead-times')) {
				data.startAdvertisingDate = this.model.fields.startAdvertisingDate
			}

			if (this.hasField('productAvailabilities')) {
				data.productAvailabilities = this.model.fields.productAvailabilities
			}

			if (this.hasField('productContingents')) {
				if (data.productAvailabilities?.de?.length) {
					for (const availability of data.productAvailabilities.de) {
						if (availability.fields.productContingents?.de?.length) {
							for (let contingent of availability.fields.productContingents.de) {
								contingent.fields.minimumContingent.de = parseInt(contingent.fields.minimumContingent.de)
								contingent.fields.maximumContingent.de = parseInt(contingent.fields.maximumContingent.de)

								//MYS-3346: ContingentService - persist original timeslot
								const initAvailability = this.initData.productAvailabilities.de.find(pa => pa.sys.id === availability.sys.id)

								if (initAvailability) {
									const initContingent = initAvailability.fields.productContingents.de.find(pc => pc.sys.id === contingent.sys.id)
									if (initContingent && initContingent.fields.timeSlot.de !== contingent.fields.timeSlot.de) {
										contingent.addl = { timeSlot: initContingent.fields.timeSlot.de?.length ? initContingent.fields.timeSlot.de : '00:00' }
									}
								}
							}
						}
					}
				}
			}

			if (this.hasField('leadTime') && !this.featureEnabled('new-lead-times')) {
				data.leadTimeMonths = this.model.fields.leadTimeMonths
				data.leadTimeWeeks = this.model.fields.leadTimeWeeks
				data.leadTimeDays = this.model.fields.leadTimeDays
				data.leadTimeHours = this.model.fields.leadTimeHours
			}

			data.changed = !!(!isEqual(data, this.initData) || this.csError);

			return data
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)

			if (this.productIsExternal) return allFieldsAreValid

			if (this.hasField('productAvailabilities')) {
				let haveAvailabilities = false
				let haveTimeFrame = false
				let haveContingents = false
				let overlappingAvaliabilities = false

				if (this.model.fields.productAvailabilities.de.length > 0) {
					haveAvailabilities = true
					haveTimeFrame = true
					haveContingents = true

					for (const availability of this.model.fields.productAvailabilities.de) {
						if (!availability || !availability.fields) {
							haveTimeFrame = false
							continue;
						}
						if (!availability.fields.validFromDate?.de || !availability.fields.validToDate?.de ||
							availability.fields.validFromDate.de.length === 0 || availability.fields.validToDate.de.length === 0) {
							haveTimeFrame = false
						}
						if (this.hasField('productContingents')) {
							if (!availability.fields.productContingents?.de?.length) {
								haveContingents = false
								continue
							}
							for (const contingent of availability.fields.productContingents.de) {
								if (!contingent.fields.maximumContingent?.de || contingent.fields.maximumContingent.de === '0') {
									haveContingents = false
								}
							}
						}
						if (availability.fields.validFromDate?.de && availability.fields.validToDate?.de) {
							const fromDate = new Date(availability.fields.validFromDate.de)
							const toDate = new Date(availability.fields.validToDate.de)
							if (fromDate > toDate) {
								this.setSectionError(this.sectionMessage, this.$t('text.invalidTimeFrameError'))
							}

							// check if the dates of the current availability overlap with the dates of any other availability
							for (const otherAvailability of this.model.fields.productAvailabilities.de) {
								if (availability.sys.id === otherAvailability.sys.id) continue
								if (!otherAvailability.fields.validFromDate?.de || !otherAvailability.fields.validToDate?.de) continue
								const otherFromDate = new Date(otherAvailability.fields.validFromDate.de)
								const otherToDate = new Date(otherAvailability.fields.validToDate.de)
								if (fromDate <= otherToDate && otherFromDate <= toDate) {
									const otherContingents = otherAvailability.fields.productContingents?.de
									const currentContingents = availability.fields.productContingents?.de
									if (otherContingents && currentContingents) {
										const overlappingTimeslotFound = otherContingents.some(otherContingent => {
											return currentContingents.some(currentContingent => {
												return otherContingent.fields.timeSlot.de === currentContingent.fields.timeSlot.de
											})
										})
										overlappingAvaliabilities = overlappingTimeslotFound
									}
								}
							}
						}
					}
				}
				if (!haveAvailabilities || !haveContingents || !haveTimeFrame) {
					allFieldsAreValid = false
					this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				} else if (this.contingentProblem) {
					allFieldsAreValid = false
					this.setSectionError(this.sectionMessage, this.$t('text.contingentError'))
				} else if (this.timeSlotProblem) {
					allFieldsAreValid = false
					this.setSectionError(this.sectionMessage, this.$t('text.contingentTimeSlotError'))
				} else if (overlappingAvaliabilities) {
					allFieldsAreValid = false
					this.setSectionError(this.sectionMessage, this.$t('text.overlappingTimeFrameError'))
				}
			}

			return allFieldsAreValid
		},
		// TODO: unused
		calcValidFromDateMin(availabilityIndex) {
			if (!this.isNewProduct && this.productWasOnceApproved && this.originalAvailabilities?.[availabilityIndex]?.fields?.validFromDate?.de?.length > 0) {
				return this.originalAvailabilities[availabilityIndex].fields.validToDate.de
			}

			return this.model.fields?.productAvailabilities?.de?.[availabilityIndex]?.fields?.validFromDate?.de
		},
		// TODO: unused
		validFromDateDisabled(availabilityIndex) {
			return !this.isNewProduct && this.productWasOnceApproved &&
				!this.checkSavedStatusForAllClientAssignments() &&
				this.originalAvailabilities?.[availabilityIndex]?.sys?.id.indexOf('id_') < 0 &&
				this.originalAvailabilities?.[availabilityIndex]?.fields?.validFromDate?.de?.length > 0
		},
		// TODO: unused
		calcValidFromDateMax(availabilityIndex) {
			// the maximum of the validFrom date should be the validTo date value for that timeframe
			return this.model.fields.productAvailabilities.de[availabilityIndex]?.fields?.validToDate?.de
		},
		// TODO: unused if validFromDateDisabled is unused
		checkSavedStatusForAllClientAssignments() {
			for (const clientAssignment of this.model.fields.clientAssignments.de) {
				if (clientAssignment.fields?.status?.de !== 'saved') {
					return false
				}
			}
			return true
		},
		hasField(fieldName) {
			return this.model.fields?.serviceType?.de?.fields?.template?.de?.availabilityInfo?.[fieldName]
		},
		getNewAvailability(counter) {
			return {
				sys: { id: 'id_av_' + counter },
				fields: {
					validFromDate: { de: '' },
					validToDate: { de: '' },
					productContingents: {
						de: [this.getNewContingent(this.counter)],
					},
					exceptions: {
						de: this.daysOfWeek,
						type: '',
					}
				}
			}
		},
		getNewContingent(counter) {
			return {
				sys: { id: 'id_con_' + counter },
				fields: {
					minimumContingent: { de: 1 },
					maximumContingent: { de: 1 },
					timeSlot: { de: '' },
				}
			}
		},
		addAvailabilityRow() {
			this.counter++;
			this.model.fields.productAvailabilities.de.push(this.getNewAvailability(this.counter))

			// update originalAvailabilities
			this.originalAvailabilities = JSON.parse(JSON.stringify(this.model.fields.productAvailabilities.de))
		},
		removeAvailabilityRow(availabilityIndex) {
			if (this.model.fields.productAvailabilities.de.length > 1) {
				this.model.fields.productAvailabilities.de.splice(availabilityIndex, 1)
			} else {
				this.counter++;
				this.model.fields.productAvailabilities.de = [this.getNewAvailability(this.counter)]
			}
			// update originalAvailabilities
			this.originalAvailabilities = JSON.parse(JSON.stringify(this.model.fields.productAvailabilities.de))
		},
		addContingentRow(availabilityIndex) {
			this.model.fields.productAvailabilities.de[availabilityIndex].fields.productContingents.de.push(this.getNewContingent(Math.random()))
		},
		removeContingentRow(availabilityIndex, contingentIndex) {
			this.model.fields.productAvailabilities.de[availabilityIndex].fields.productContingents.de.splice(contingentIndex, 1)
		},
		fixTime(t) {
			if (t.length == 0) return ''
			if (t.length == 1) return `0${t}:00`
			if (t.length == 2) return `${t}:00`
			if (t.length == 3) return `${t}00`
			if (t.length == 4) return `${t}0`
			return t
		},
		checkNumber(event) {
			if (event.target.value < 0) {
				event.target.value = 1
			}
		},
		validFromToError(availability, prop) {
			let error
			if (!availability.fields[prop]?.de?.length) {
				error = this.$t('text.missingFieldsError')
			} else {
				//MYS-3346: ContingentService - Check if it's the from or the to date that has changed to exclude an existing reservation
				if (this.csError?.data?.availabilityId === availability.sys.id) {
					if (prop === 'validFromDate') {
						if (this.csError.data.errorItems.find(error => error.startDateTime.split(' ')[0] < availability.fields.validFromDate.de)) {
							error = 'Contingent Service Error'
						}
					} else {
						if (this.csError.data.errorItems.find(error => error.startDateTime.split(' ')[0] > availability.fields.validToDate.de)) {
							error = 'Contingent Service Error'
						}
					}
				}
			}
			return error
		},
		contingentError(contingent) {
			let error

			if (!contingent.fields.maximumContingent?.de || contingent.fields.maximumContingent.de === 0) {
				error = this.$t('text.missingFieldsError')
			} else {
				//MYS-3346: ContingentService - Check if there is an error where the contingent amount is less than the number of reservations
				if (this.csError?.data?.productContingentId === contingent.sys.id) {
					if (this.csError.data.errorItems.find(error => error.reservationCount > contingent.fields.maximumContingent.de)) {
						error = 'Contingent Service Error'
					}
				}
			}
			return error
		},
		timeSlotError(contingent) {
			let error
			this.timeSlotProblem = false

			// loop over every availability and every contingent and make sure that the time slots are different in every contingent of that availability. 
			// We do this because if we only check the current contingent, the user could change the time slot of the current contingent to be the same and then change the time slot 
			// of a contingent in another availability to be different from the other contingent(s) there and the error would not be shown for the previous availability.
			for (let i = 0; i < this.model.fields.productAvailabilities.de.length; i++) {
				let availability = this.model.fields.productAvailabilities.de[i]
				for (let j = 0; j < availability.fields.productContingents.de.length; j++) {
					let contingent = availability.fields.productContingents.de[j]

					for (let k = 0; k < availability.fields.productContingents.de.length; k++) {
						let otherContingent = availability.fields.productContingents.de[k]
						if (contingent.fields.timeSlot.de === otherContingent.fields.timeSlot.de && j !== k) {
							error = this.$t('text.contingentTimeSlotError')
							this.timeSlotProblem = true
						}
					}
				}
			}

			if (!error) {
				//MYS-3346: ContingentService - Check if there is an error with the time slot
				if (this.csError?.data?.productContingentId === contingent.sys.id) {
					if (this.csError.data.errorItems.find(error => error.startDateTime.split(' ')[1] !== contingent.fields.timeSlot.de)) {
						error = 'Contingent Service Error'
					}
				}
			}

			return error
		},
		setAvailabilityDetails() {
			for (const availability of this.model.fields.productAvailabilities.de) {
				if (!availability.fields.exceptions) {
					availability.fields['exceptions'] = { de: this.daysOfWeek }
				}

				if (!availability.fields.productContingents) {
					availability.fields["productContingents"] = { de: [this.getNewContingent(1)] }
				}
			}
		}
	},
	created() {
		this.model = this.valueToModel(this.product)
		this.setAvailabilityDetails()
	},
	mounted() {
		this.isNewProduct = this.product.sys.id === ''
		// copy product availabilities so that we have a reference of the original data
		this.originalAvailabilities = JSON.parse(JSON.stringify(this.model.fields.productAvailabilities.de))

		// MYS-4662: the backend was catering for this before, but IMO the frontend should be able to initialize availabilities if they are not present.
		console.log('Initializing productAvailabilities', this.model.fields.productAvailabilities?.de?.length)
		if (!this.model.fields.productAvailabilities?.de?.length) {
			this.model.fields.productAvailabilities = { de: [this.getNewAvailability(1)] }
		} else {
			this.model.fields.productAvailabilities.de.forEach((availability, index) => {
				if (!availability.fields.productContingents?.de?.length) {
					availability.fields.productContingents = { de: [this.getNewContingent(index)] }
				}
			})
		}
		this.setInitData()
	},
}
</script>
<style scoped>
	h4 {
		color: #000000;
		font-size: 14pt;
		text-align: left;
		font-weight: bold;
	}

	.overflowButton {
		background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
		display: flex !important;
		justify-content: space-around !important;
		align-items: center !important;
		border-radius: 5px !important;
		font-family: 'Inter', sans-serif;
		font-size: 12pt !important;
		text-transform: none !important;
		letter-spacing: 0 !important;
	}

	.v-overflow-btn.v-input--is-focused .v-input__slot,
	.v-overflow-btn.v-combobox--is-menu-active .v-input__slot {
		box-shadow: none !important;
		border: solid 1px rgba(0, 0, 0, 0.1) !important;
	}

	.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.error-text {
		color: var(--error-color);
	}

	.error-container {
		background-color: #f34545;
		border-radius: 10px;
		padding: 10px;
		color: #ffffff
	}

	.error-container #errors {
		color: #ffffff;
		font-size: 14pt;
	}

	div.col2 {
		display: flex;
		gap: 10px;
		align-items: center;
	}

	div.col2>* {
		width: 50%;
	}

	div.col4 {
		display: flex;
		gap: 10px;
		align-items: center;
	}

	div.col4>* {
		width: 25%;
	}

	.red-text {
		color: #f34545;
	}
</style>