<template>
	<div class="VDatePicker">
		<v-label v-if="$slots.label"><slot name="label"></slot></v-label>
		<div>
			<v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" activator="parent" :disabled="disabled" @update:menu="onMenuUpdate">
				<template #activator="{ props }"><v-text-field v-bind="props" :readonly="true" variant="outlined" density="compact" hide-details class="vfield" :placeholder="placeholder" v-model="formattedDate" clearable @click:clear="clearDate" :disabled="disabled" /></template>
				<v-date-picker ref="datePicker" v-model="model" :month="initMonth" :year="initYear" :min="min" :max="max" :allowed-dates="allowedDates" show-adjacent-months @update:modelValue="menu = false" />
			</v-menu>
		</div>
		<div class="errors">{{ errors }}</div>
		<slot name="helperText" />
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		modelValue: String,
		min: String,
		max: String,
		disabledDates: Array,
		errors: String,
		dataCy: String,
		placeholder: String,
		disabled: Boolean,
		initDate: String
	},
	data: () => ({
		menu: false,
	}),
	computed: {
		model: {
			get() {
				if (this.modelValue instanceof Date) {
					return this.modelValue
				}

				if (typeof this.modelValue === 'string' && this.modelValue) {
					return moment(this.modelValue, 'YYYY-MM-DD').toDate()
				}

				return null
			},
			set(value) {
				this.$emit('update:modelValue', value ? moment(value).format('YYYY-MM-DD') : null)
			}
		},
		formattedDate() {
			return this.model ? moment(this.model).format('DD.MM.YYYY') : ''
		},
		initMonth() {
			if (this.initDate && !this.model) {
				return moment(this.initDate).month()
			}
			return null
		},
		initYear() {
			if (this.initDate && !this.model) {
				return moment(this.initDate).year()
			}
			return null
		}
	},
	methods: {
		clearDate() {
			this.model = null
			this.$emit('update:modelValue', null)
		},
		allowedDates(date) {
			return this.disabledDates ? !this.disabledDates.includes(moment(date).format('YYYY-MM-DD')) : true
		},
	}
}
</script>

<style scoped>
.errors { color: var(--error-color); }
</style>