<template>
	<v-tooltip :class="{ 'white-background' : whiteBackground }" location="bottom end" no-click-animation>
		<template v-slot:activator="{ props }">
			<v-icon v-bind="props" size="small">mdi-information</v-icon>
		</template>
		<div v-if="keyPrefix" style="width: 300px;">
			<h1 v-html="title" />
			<p v-html="text" />
		</div>
		<slot />
	</v-tooltip>
</template>

<script lang="ts">
export default {
	props: {
		keyPrefix: String,
		whiteBackground: Boolean
	},
	computed: {
		title() {
			return this.$t('text.' + this.keyPrefix + '_helpTitle')
		},
		text() {
			return this.$t('text.' + this.keyPrefix + '_help')
		},
	},
}
</script>

<style scoped>
.v-icon { color: gray; }
.v-icon:hover { color: var(--col-selected); }
h1 { margin: 0; padding: 0; font-size: inherit; font-weight: bold; line-height: 100%; margin-bottom: 5px; }
p { font-size: inherit; }
</style>

<style>
.white-background .v-overlay__content {
	background-color: white;
	color: black;
}
</style>