<template>
	<div class="LeadTimeSelector">
		<div class="fieldTitleContainer">
			<div>
				<p class="fieldTitle">{{ $t('text.leadTime') }}</p>
				<HelpIcon keyPrefix="leadtime_selector" :whiteBackground="true" />
			</div>
			<p class="helpText">{{ $t('text.leadTimeInfo') }}</p>
		</div>
		<div class="Field"><RadioSelect v-model="dropdownModel" :options="leadTimes" :celarable="true" :useRawValue="true" :placeholder="$t('text.selectLeadTime')" /></div>
		<div class="customContainer" v-if="dropdownModelValue === 5">
			<div class="fieldTitleContainer"><p class="fieldTitle">{{ $t('text.customSetting') }}</p></div>
			<div class="customContainerFields">
				<NumberField class="Field field" v-model="model.leadTimeMonths" :unit="$t('text.months').toLowerCase()" :min="0" :field="dummyFieldDefinition" @blur="setZeroIfEmpty('leadTimeMonths')" />
				<NumberField class="Field field" v-model="model.leadTimeDays" :unit="$t('text.days').toLowerCase()" :min="0" :field="dummyFieldDefinition" @blur="setZeroIfEmpty('leadTimeDays')" />
				<NumberField class="Field field" v-model="model.leadTimeHours" :unit="$t('text.hours').toLowerCase()" :min="0" :field="dummyFieldDefinition" @blur="setZeroIfEmpty('leadTimeHours')" />
			</div>
		</div>
	</div>
</template>

<script>
import Common from "@/mixins/Common.vue"
import RadioSelect from '../common/RadioSelect.vue'
import NumberField from '../fields/NumberField.vue'
import HelpIcon from '../common/HelpIcon.vue'

export default {
	name: 'LeadTimeSelector',
	components: { RadioSelect, NumberField, HelpIcon },
	mixins: [ Common ],
	props: {
		modelValue: Object,
	},
	data: () => ({
		dummyFieldDefinition: { validations: [] },
		model: {},
		dropdownModel: null,
		initModelDone: false
	}),
	watch: {
		dropdownModelValue(newValue) {
			if (!this.initModelDone)
				return
			if (!newValue || newValue === 5) {
				this.model = {
					leadTimeDays: 0,
					leadTimeMonths: 0,
					leadTimeWeeks: 0,
					leadTimeHours: 0
				}
			} else {
				this.model = {
					leadTimeDays: newValue,
					leadTimeMonths: 0,
					leadTimeWeeks: 0,
					leadTimeHours: 0
				}
			}
		},
		model: {
			deep: true,
			handler() {
				const keys = Object.keys(this.model)
				for (const key of keys) {
					if (this.model[key] < 0) {
						this.model[key] = 0
					}
				}
				this.$emit('update:modelValue', this.model)
			},
		}
	},
	computed: {
		leadTimes() {
			return [
				{
					title: `1 ${this.$t('text.day') }`,
					value: 1,
				},
				{
					title: `2 ${this.capitalizeFirstLetter(this.$t('text.days'))}`,
					value: 2,
				},
				{
					title: `3 ${this.capitalizeFirstLetter(this.$t('text.days'))}`,
					value: 3,
				},
				{
					title: `4 ${this.capitalizeFirstLetter(this.$t('text.days'))}`,
					value: 4,
				},
				{
					title: this.$t('text.custom'),
					value: 5,
				},
			]
		},
		dropdownModelValue() {
			return this.dropdownModel?.value
		}
	},
	methods: {
		capitalizeFirstLetter(val) {
			return String(val).charAt(0).toUpperCase() + String(val).slice(1);
		},
		setZeroIfEmpty(key) {
			if (!this.model[key]) {
				this.model[key] = 0
			}
		}
	},
	created() {
		if (this.modelValue.leadTimeDays < 5 && !this.modelValue.leadTimeMonths && !this.modelValue.leadTimeWeeks && !this.modelValue.leadTimeHours) {
			this.dropdownModel = this.leadTimes.find(x => x.value === this.modelValue.leadTimeDays)
		}
		else if (this.modelValue.leadTimeDays || this.modelValue.leadTimeMonths || this.modelValue.leadTimeWeeks || this.modelValue.leadTimeHours) {
			this.dropdownModel = this.leadTimes.find(x => x.value === 5)
		}
	},
	mounted() {
		this.model = this.modelValue
		this.initModelDone = true
	},
}
</script>